import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { Subject } from 'rxjs';


const apiUrl = environment.apiUrl;
@Injectable({
  providedIn: 'root'
})
export class CurrentDataService { 
  queryParamsiIrregularities;
  sendData = new Subject<any>();

  constructor(private http: HttpClient) { }
    
  private decodeToken() {
    const token = localStorage.getItem('token');
    if ( token ) {
      const jwtData = token.split('.')[1];

      const decodedJwtJsonData = this.b64DecodeUnicode(jwtData);
      const decodedJwtData = JSON.parse(decodedJwtJsonData);
    return decodedJwtData;
    }
    return
  }

  b64DecodeUnicode(str) {
    return decodeURIComponent(Array.prototype.map.call(atob(str), function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''))
}


  
  getProfile() {
    if( this.decodeToken() ) {
      return JSON.parse(this.decodeToken().Profile);
    }
    return
  }
  getHeaders(): HttpHeaders {
    const headers = new HttpHeaders({
      'Authorization':  localStorage.getItem('token')
    });
    return headers;
  }

  getDataToken(token, session = true) {
    let headers = this.getHeaders();
    let sender = {
      token: token,
      session: session
    }
    if ( token ) {
      return this.http.post(apiUrl +`/TokenDecode/getInformation`, sender,  {headers}).subscribe(
        (data: any) => { 
          if( !session ) {
            localStorage.setItem('token', data.Token );
          }
        });
      }
    }
    isValidateSession() {
      const token = localStorage.getItem('token').slice(7);
      const jwtData = token.split('.')[1];
      const decodedJwtJsonData = this.b64DecodeUnicode(jwtData);
      const decodedJwtData = JSON.parse(decodedJwtJsonData);   
  
        let fecha1 = moment(new Date());
        let fecha2 = moment(new Date( decodedJwtData.exp * 1000));
        let minutosRestantes = fecha2.diff(fecha1, 'minutes');
        
        if ( minutosRestantes <= 20 && minutosRestantes >= 0) {
           this.getDataToken(token,false);
        }
    }
  setQueryParamsIrregularities(data) {
    this.queryParamsiIrregularities = data;
    this.sendData.next(this.queryParamsiIrregularities)
  }    
}
