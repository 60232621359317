export const spanish = {
  modules: {
    cat_materials: {
      title: 'Catálogo de Materiales',
      modals: {
        edit: {
          title: 'Editar Material',

        },
        add: {
          title: 'Agregar Material',

        },
        delete: {
          title: '¿Está seguro de eliminar el material?',

        },
        labels: {
          codeSisea: '¿Es codigo SISEA?',
          partNumber: 'Escriba el Número de Parte',
          shortDescription: 'Escriba la Descripción Corta',
          descriptionEnglish: 'Escriba la Descripción en Inglés',
          typeofSupply: 'Seleccione un Tipo de Abastecimiento',
          familydescription: 'Seleccione una Descripción de Familia',
          status: 'Marque el Status',
          image: 'Seleccione la Foto',
          weight: 'Peso',
        },
        messages: {
          imageErrorMsg: 'Tamaño de foto inválido! , (tamaño máximo de 3 MB)',
          materialAddMsg: 'Se agregó correctamente el material ',
          materialAddMsgAgain: 'No se agregó correctamente, volver a intentar',
          materialEditMsg: 'Se editó correctamente el material',
          materialEditMsgAgain: 'No se editó correctamente, volver a intentar',
          materialDeleteQuestion: '¿Está seguro de eliminar el material?',
          materialDeleteMsg: 'Se elimino correctamente',
          materialDeleteMsgAgain: 'No se eliminó correctamente, volver a intentar',


        }
      },
      table: {
        labels: {
          partNumber: 'Número de Parte',
          shortDescription: 'Descripción Corta',
          descriptionEnglish: 'Descripción Inglés',
          typeofSupply: 'Tipo de Abastecimiento',
          familydescription: 'Descripción de Familia',
          status: 'Estatus',
          image: 'Foto',
          weight: 'Peso',
        }
      }

    },
    reportIrregularities: {

      //Titles Reportes de Irregularidades
      titleModule: '',
      titleTab1: 'Vuelos',
      titleTab2: 'Reportes de Irregularidades',
      titleEdit: 'Editar Reporte de Irregularidad',
      titleAdd: 'Enviar Reporte de Irregularidad',
      titleDelete: '¿Está seguro de eliminar reporte de irregularidad?',
      titleResponse: 'Responder irregularidad',
      titleHistoryResponse: 'Historial Respuestas',

      //Messages
      AddMsg: 'Se envió correctamente el reporte de irregularidad',
      AddMsgAgain: 'No se agregó correctamente, volver a intentar',
      EditMsg: 'Se editó correctamente el reporte de irregularidad',
      ResponseMsg: 'Se respondió  correctamente el reporte de irregularidad',
      EditMsgAgain: 'No se editó correctamente, volver a intentar',
      ResponseMsgAgain: 'No se respondió  correctamente, volver a intentar',
      DeleteQuestion: '¿Está seguro de eliminar el reporte de irregularidad?',
      DeleteMsg: 'Se eliminó  correctamente',
      DeleteMsgAgain: 'No se eliminó correctamente, volver a intentar',
      CloseQuestion: '¿Está seguro de eliminar el reporte de irregularidad?',
      CloseMsg: 'Se cerró  correctamente',
      CloseMsgAgain: 'No se cerró  correctamente, volver a intentar',
      closeQuestion: '¿Está seguro de cerrar el reporte de irregularidad?',
      WarnDateMsg: 'Seleccione la ',
      ErrorDateValidateMsg: 'La fecha de inicio no puede ser menor a la fecha de termino',
      ErrorCampos: 'Advertencia: Los campos son requeridos ',
      labelAccount: 'Archivos adjuntos',
      closeIrregularity: 'Cerrar Irregularidad',

      //Labels
      labelReport: 'Reportó',
      labelSelectUsers: 'Seleccione Usuarios',
      DateStart: 'Fecha De Inicio',
      DateEnd: 'Fecha De Término',
      labelId: 'No. Id',
      labelFechaIncidente: 'Fecha De Incidente',
      labelFuente: 'Fuente',
      labelReporto: 'Reportó',
      labelAttachments: 'Comunicar a',
      labelResponsableSelect: 'Seleccione un responsable',
      labelResponsableArea: 'Seleccionaste un área completa como responsable , el responsable debe ser solo uno',
      labelCompany: 'Empresa',
      labelFiles: 'Archivos Cargados',

      labelResponsable: 'Responsable',
      labelTipoReporte: 'Tipo De Reporte',
      labelFolio: 'Folio',
      labelEstacion: 'Estación',
      labelVuelo: 'Vuelo',
      labelTramo: 'Tramo',
      labelClase: 'Clase',
      labelProductos: 'Grupos',
      labelTipoIrregularidad: 'Tipo De Irregularidad',
      labelCriteria: 'Criterio',
      labelDescripcion: 'Descripción',
      labelDescripcionEnglish: 'Descripción en Inglés',

      labelResponseIrregularity: '¿Aceptas la irregularidad?',
      labelComentario: 'Comentario',
      labelComentarioCierre: 'Comentario de cierre de irregularidad',
      labelPhoto: 'Foto',
      labelResponsableStation: 'Estación responsable',
      labelStatus: 'Estatus',
      labelHistory: 'Historial',
      open: 'Abierto',
      close: 'Cerrado',
      accept: 'Aceptar',
      reject: 'Rechazar',
      accepted: 'Aceptado',
      rejected: 'Rechazado',
      labelMaxForward: 'Se ha excedido el número de reenvios',
      fordward: 'Reasignado',
      fordwardProvider: 'Respuesta Proveedor',
      //Table headers
      ActualBlockOffUTCHeader: 'Salida de Posición',
      ActualBlockOnUTCHeader: 'Llegada a Posición',
      ActualTakeOffUTCHeader: 'Equipo Realiza Despegue UTC',
      ActualTouchDownUTCHeader: 'Tiempo de Aterrizaje UTC',
      AircraftRegistrationHeader: 'Registro de Aeronaves',
      AircraftSubTypeHeader: 'Subtipo de Aeronave',
      CarrierHeader: 'Transportista',
      DepartureAirportHeader: 'Aeropuerto de Salida',
      DepartureIndicatorHeader: 'Indicador de Salida',
      FlightDateLocalHeader: 'Fecha de Vuelo Local',
      FlightNumberHeader: 'Número de Vuelo',
      LatestArrivalAirportUTCHeader: 'Aeropuerto de Última Llegada',
      ScheduledArrivalTimeUTCHeader: 'Hora de Llegada Programada',
      ScheduledDepartureTimeUTCHeader: 'Hora de Salida Programada',
      //Table Irregularities Headers
      description: 'Descripción',
      flightDate: 'Fecha de Vuelo',
      flihtNumber: 'Número de Vuelo',
      folio: 'Folio',
      forwads: 'Reenvíos',
      forwad: 'Reenvío',
      photo64: 'Foto 64',
      photoPath: 'Foto Path',
      reportDate: 'Fecha De Reporte',
      sender: 'Enviado Por',
      forwardDate: "Fecha De Reenvío",
      lastName: 'Apellidos',
      name: 'Nombre',
      noforward: 'Número De Reenvío',
      labelDontClose: 'La irregularidad está cerrada , no se puede editar o reenviar',
      dontMultiResponsable: 'Solo puedes seleccionar un responsable',
      loadAttachments: 'Cargar Archivos',
      answer: 'Respuesta',
      labelStationProvider: 'El proveedor responsable seleccionado no pertenece a esa estación',
      labelStationProviderValidate: 'Seleccione primero un responsable antes de seleccionar la estación responsable',
      labelDontIsResponsable: 'No puedes dar forward ya que no eres el responsable de la irregularidad',
      labelDontPermissionResponse: 'No tienes permisos para responder esta irregularidad',
      labelDontPermissionEdit: 'No tienes permisos para editar esta irregularidad',
      labelResponsableValid: 'Seleccione un responsable válido',
      labelSelectDescriptionEnglish: 'Debe modificar la descripción en inglés para poder editar',
      proveedor: 'Respuesta proveedor',
      invalidFilter: 'Filtro inválido, este campo es para filtrar, no ingresar emails en este campo, para seleccionar despliegue el listado de usuarios que se encuentra abajo',
      filter: 'Filtro',
      titleEmailsNotification: 'Se notificará a ',
      textEmailsNotification: 'Les llegará un email de notificación a los siguientes contactos:',
      textResponseEmailsNotification: '¿Está de acuerdo? ',
      contacts: 'Contactos',

      labelDate: 'Fecha',
      labelArea: 'Responsable',
      labelUserEdit: 'Realizado por',
      labelUserClose: 'Cerrado por',
      labelNotifiedTo: 'Lista de notificados',
      titleEmailsNotificationInfo: 'Se notificó a los siguientes contactos',
      labelNotificateDirectBoss: '¿Notificar a jefe directo?',
      labelDontStationAutomaticResponsable: 'Para tener un responsable en automático se debe seleccionar también la estación responsable',
      labelDontProductAutomaticResponsable: 'Para tener un responsable en automático se debe seleccionar también el grupo, irregularidad y criterio',
      labelDontMultipleResponsable: 'No se puede seleccionar más de un responsable',
      labelDontAreaResponsable: 'No puedes elegir un área como responsable',
      labelDontResponsableAndDirectBoss: 'No hay responsable ni jefe directo asignado para esta estación, asigne una manualmente',
      labelDontStationAndCriteriaAutomaticResponsable: 'Para tener un responsable en automático se debe seleccionar también el criterio y la estación',
      labelDontCriteriaAutomaticResponsable: 'Para tener un responsable en automático se debe seleccionar también el criterio',
      labelDontDirectBoss: 'No hay jefe directo asignado',
      labelDirectBoss: 'Jefe Directo',


    },
    statistics: {
      //Titles
      titleModule: 'Estadísticas',
      titleEdit: 'Editar Estadística',
      titleAdd: 'Agregar Estadística',
      titleDelete: '¿Está seguro de eliminar estadística?',
      filterTitle: 'Filtros Grupos',
      //Messages
      AddMsg: 'Se agregó correctamente la estadística ',
      AddMsgAgain: 'No se agregó correctamente, volver a intentar',
      EditMsg: 'Se editó correctamente la estadística',
      EditMsgAgain: 'No se editó correctamente, volver a intentar',
      DeleteQuestion: '¿Está seguro de eliminar la estadística?',
      DeleteMsg: 'Se elimino correctamente',
      DeleteMsgAgain: 'No se eliminó correctamente, volver a intentar',
      WarnDateMsg: 'Seleccione ',
      ErrorDateValidateMsg: 'La fecha de inicio no puede ser menor a la fecha de termino',
      //Labels
      DateStart: 'Fecha de Inicio',
      DateEnd: 'Fecha de Termino',
      //Table headers
      labelFolio: 'Folio',
      labelFechaIncidente: 'Fecha de Incidente',
      labelFuente: 'Fuente',
      labelReporto: 'Reportó',
      labelFlightNumber: 'Vuelo',
      labelTramo: 'Tramo',
      labelResponsable: 'Responsable',
      labelEstacion: 'Estación',
      labelTipoReporte: 'Tipo de Reporte',
      labelProductos: 'Grupos',
      labelTipoIrregularidad: 'Tipo de Irregularidad',
      labelClase: 'Clase',
      labelDescripcion: 'Descripción',
      labelStation: 'Estación',
      labelProvider: 'Proveedor',
      status: 'Estatus',
      labelCriteria: 'Criterio',
    },
    restockCap: {
      //Titles
      titleModule: 'Topes de Reabasto de Líquidos',
      //Table Headers
      idRestockCap: 'Identificador',
      nameRestock: 'Nombre',
      station: 'Estación',
      region: 'Región',
      status: 'Estatus',

      //Modal Messages
      titleAdd: 'Agregar tope de reabasto de líquidos',
      excelErrorMsg: 'Archivo excel incorrecto',
      excelFileNameError: 'Nombre de archivo incorrecto',
      //Labels
      labelExcel: 'Selecciona un archivo Excel',
      //Messages
      AddMsg: 'Se terminó correctamente la carga del archivo, verifica los cambios',
      AddMsgAgain: 'No se agregó correctamente, volver a intentar',
      DeleteQuestion: '¿Está seguro de eliminar el tope de reabasto?',
      DeleteMsg: 'Se elimino correctamente',
      DeleteMsgAgain: 'No se eliminó correctamente, volver a intentar',
      NoFileMsg: 'Falta agregar documento excel de topes de reabasto de líquidos'

    },
    boardingTable: {
      titleModule: 'Tablas De Abordamiento',
      titleEdit: 'Editar Tabla De Abordamiento',
      titleAdd: 'Agregar Tabla De Abordamiento',
      titleDelete: '¿Está seguro de eliminar la tabla de abordamiento?',
      titleMessages: 'Mensajes',
      //Messages
      ExcelErrorMsg: 'Tamaño del excel inválido! , (tamaño máximo de 3 MB)',
      AddMsg: 'Se leyó correctamente el archivo, verifica los datos',
      AddMsgAgain: 'No se agregó correctamente, volver a intentar',
      EditMsg: 'Se editó correctamente la tabla de abordameinto',
      EditMsgAgain: 'No se editó correctamente, volver a intentar',
      DeleteQuestion: '¿Está seguro de eliminar la tabla de abordameinto?',
      DeleteMsg: 'Se elimino correctamente',
      DeleteMsgAgain: 'No se eliminó correctamente, volver a intentar',


      //Labels
      labelExcel: 'Seleccione El Excel',

      //Table headers
      airline: 'Aerolínea',
      cabine: 'Cabina',
      origen: 'Origen',
      destine: 'Destino',
      equipment: 'Equipo',
      codServ: 'Código De Servicio',
      dateInit: 'Fecha De Inicio',
      dateEnd: 'Fecha De Fin',
      cycles: 'Ciclos',
      section: 'Sección',
      // Table Modal
      errorMessage: 'Mensaje de error',
      isInsert: '¿Se Guardo?',
      descServ: 'Descripción De Servicio',
      codServErr: 'Código de Servicio ó Dato de la Tabla',
      orderReg: 'Ordenar Registros',
      finish: 'Finalizar',
      uploadFile: 'Cargar Archivo',
      msgUpload: 'Arrastre y suelte sus archivos aquí',
      msgUploadOr: 'o',
      btnBrowseFile: 'Seleccionar Archivo',
      nameTableFile: 'Nombre del archivo',
      //Propiedades de breadcrumb
      addOnBoard: 'Crear Tabla de Abordamiento',
      addOnBoardStation: "Estaciones a cargar:",
      addOnBoardCheck: "Por favor verifique que el orden de los datos sean correctos."
    },
    preselectemails: {
      langTitleMenu: "Catálogo de Correo Electrónico",
      langCorrectEmailInsert: "El correo electrónico ha sido agregado correctamente.",
      langEmailExists: "El correo electrónico ya existe en la lista de destinatarios.",
      langAdd: "Agregar",
      langReportType: "Tipo Reporte",
      langTitleInsertModal: "Insertar Correo Electrónico",
      langEmailLabel: "Correo Electrónico",
      langStationLabel: "Estación",
      langSaveButton: "Guardar",
      langColumnEmail: "Correo Electrónico",
      langColumnStation: "Estación",
      langColumnActive: "Estatus",
      langEdit: "Editar",
      langLoading: "Cargando",
      langTitleEditModal: "Editar Correo Electrónico",
      langSlideStatus: "Estatus Correo Electrónico",
      langCorrectEmailUpdate: "El correo electrónico ha sido actualizado correctamente.",
      langRequiredEmail: "El correo electrónico es requerido",
      langRequiredStation: "Seleccione al menos una estación",
      langIncorrectFormatEmail: "El correo electrónico no tiene el formato correcto",
      langInvalidRequest: "Solicitud no válida para el servidor.",
      langInternalServerError: "El servidor presentó un error interno al procesar la solicitud.",
      langUnauthorizedRequest: "Acceso no autorizado al servidor.",
      langErrorTitleMessage: "Error:",
      langSuccessTitleMessage: "Correcto:",
      langLabelItemsByPage: "Filas por página:",
      langLabelOf: "de",
      langStationCheckAllLabel: 'Seleccionar todo'
    },
    tsu: {
      titleModule: 'TSU',
      titleEdit: 'Editar TSU',
      titleAdd: 'Agregar TSU',
      titleDelete: '¿Está seguro de eliminar el TSU?',
      titleMessages: 'Mensajes',
      //Messages
      ExcelErrorMsg: 'Tamaño del excel inválido! , (tamaño máximo de 3 MB)',
      AddMsg: 'Se agregó correctamente el TSU',
      AddMsgAgain: 'No se agregó correctamente, volver a intentar',
      EditMsg: 'Se editó correctamente el TSU',
      EditMsgAgain: 'No se editó correctamente, volver a intentar',
      DeleteQuestion: '¿Está seguro de eliminar el TSU?',
      DeleteMsg: 'Se elimino correctamente',
      DeleteMsgAgain: 'No se eliminó correctamente, volver a intentar',


      //Labels
      labelExcel: 'Seleccione el excel',

      //Table headers
      idMaterial: 'Id Material',
      description: 'Descripción',
      partNumber: 'Número de parte',
      observation: 'Observación',
      idTsu: 'Id tsu',
      name: 'Nombre',
      classe: 'Clase',
      photo: 'Foto',
      quantity: 'Cantidad',
      scheme: 'Esquema',
      imageErrorMsg: 'Tamaño de la imagen inválido! , (tamaño máximo de 3 MB)',

      // Table Modal
      errorPartNumber: 'El número de parte es requerido',
      errorObservation: 'La observación es requerida',
      errorName: 'El nombre es requerido',
      errorClasse: 'La clase es requerida',
      errorPhoto: 'La foto es requerida',
      kitCreated: 'Kit creado',
      materials: 'Materiales',
      observations: 'Observaciones',
      addKit: 'Agregar Kit',
      commentary: 'Comentario',
      errorCommentary: 'El comentario es requerido',
      errorScheme: 'El esquema es requerido',
      excel: 'Descargar Excel'

    },
    serviceGuides: {
      titleModule: 'Guía de servicio',
      titleEdit: 'Editar Guía de Servicio',
      titleAdd: 'Agregar Guía de Servicio',
      titleDelete: '¿Está seguro de eliminar la guia de servicio?',
      titleMessages: 'Mensajes',
      //Messages
      ExcelErrorMsg: 'Tamaño del excel inválido! , (tamaño máximo de 3 MB)',
      AddMsg: 'Se agregó correctamente la guía de servicio',
      AddMsgAgain: 'No se agregó correctamente, volver a intentar',
      EditMsg: 'Se editó correctamente la guía de servicio',
      EditMsgAgain: 'No se editó correctamente, volver a intentar',
      DeleteQuestion: '¿Está seguro de eliminar la guía de servicio?',
      DeleteMsg: 'Se elimino correctamente',
      DeleteMsgAgain: 'No se eliminó correctamente, volver a intentar',
      //Labels
      labelExcel: 'Seleccione El Excel',

      //Table headers
      nameFile: 'Nombre',
      airlineType: 'Tipo de Aerolínea',
      month: 'Mes',
      // Table Modal

      incorrectNomenclature: 'La nomenclatura del archivo es incorrecta',
      fileNotSelected: 'No se selecciono archivo',

    },
    fleetList: {
      titleModule: 'Relación de Flota',
      titleEdit: 'Editar Relación De Flota',
      titleAdd: 'Agregar Relación De Flota',
      titleDelete: '¿Está Seguro De Eliminar La Relación De Flota?',
      titleCommnets: 'Agregar Comentario',
      //Messages
      AddMsg: 'Se agregó correctamente la relación de flota',
      AddMsgAgain: 'No se agregó correctamente, volver a intentar',
      AddMsgCommnet: 'Se agregó correctamente el comentario en la familia',
      EditMsg: 'Se editó correctamente la relación de flota',
      EditMsgAgain: 'No se editó correctamente, volver a intentar',
      DeleteQuestion: '¿Está seguro de eliminar la relación de flota?',
      DeleteMsg: 'Se elimino correctamente la relación flota ',
      DeleteMsgAgain: 'No se eliminó correctamente, volver a intentar',
      errorForm: 'El campo es requerido ',
      //Labels
      idPlane: 'ID',
      matPlane: 'Matricula',
      shortMatPlane: 'Matricula Corta',
      model: 'Modelo',
      subModelType: 'Submodelo',
      seats: 'Total De Asientos',
      seatsPremier: 'Asientos Premier',
      seatsTourist: 'Asientos Turista',
      airline: 'Aerolínea',
      family: 'Familia',
      meals: 'Alimentos',
      ovens: 'Hornos',
      notes: 'Notas',
      yEntertainment: 'Equipo De Entretenimiento Turista',
      jEntertainment: 'Equipo De Entretenimiento Premier',
      jHeadphones: 'Audífonos Premier',
      yHeadphones: 'Audífonos Turista',
      jWC: 'Baños  Premier',
      Ywc: 'Baños Turista',
      jStationery: 'Papelería Premier',
      yStationery: 'Papelería Turista',
      connectivity: 'Conectividad',
      commnet: 'Comentario',
      addComment: 'Agregar comentario',
      plane: 'Seleccione un equipo',
      row: 'Seleccione una fila',

      //Table headers

      // Table Modal

    },
    matrix: {
      titleModule: 'Matriz',
      titleDetail: 'Detalle Matriz',
      titleEdit: 'Editar matriz',
      titleAdd: 'Agregar matriz',
      titleDelete: '¿Está seguro de eliminar la matriz?',
      titleMessages: 'Mensajes',
      //Messages
      ExcelErrorMsg: 'Tamaño del excel inválido! , (tamaño máximo de 3 MB)',
      AddMsg: 'Se agregó correctamente la matriz',
      AddMsgAgain: 'No se agregó correctamente, volver a intentar',
      EditMsg: 'Se editó correctamente la matriz',
      EditMsgAgain: 'No se editó correctamente, volver a intentar',
      DeleteQuestion: '¿Está seguro de eliminar la matriz?',
      DeleteMsg: 'Se elimino correctamente',
      DeleteMsgAgain: 'No se eliminó correctamente, volver a intentar',
      //Labels
      labelExcel: 'Seleccione el excel',
      //Table headers
      titleHeader: 'Matriz de Artículos de Comisariato',
      equipment: 'Equipo',
      enrollment: 'Matrícula',
      totalPax: 'Total de Pax',
      lot: 'Lote/NP',
      um: 'UM',
      description: 'Descripción',
      picture: 'Foto',
      dateModified: 'Inicio de Vigencia',

      kitCreated: 'Kit creado',
      equipmentFamily: 'Equipo',
      product: 'Grupo',
      touristQuantity: 'Cantidad Turista',
      premierQuantity: 'Cantidad Premier',
      lote: 'Lote',
      msgselectLot: 'Debe seleccionar un lote',
      msgEquipmentSelect: 'Debe seleccionar un equipo',
      msgProduct: 'Debe seleccionar un grupo',
      msgQuantityTourist: 'Debe escrbir una cantidad turista',
      msgQuantityPremier: 'Debe escrbir una cantidad premier',
      matrixForm: 'Formulario Matriz',
      editMatrixForm: 'Editar formulario Matriz',
      selectEquipment: 'Seleccione un equipo',
      selectProduct: 'Seleccione un grupo',
      effectiveDateLabel: 'Inicio de Vigencia'
    },
    generalConsult: {
      nameView: 'Ver Potencial',
      nameDownload: 'Descargar Potencial',
      titleConsultGeneral: 'Consulta General',
      titleIrregularities: 'Irregularidades',
      titleBoardingTable: 'Tablas de Abordamiento',
      titleRestockCap: 'Topes de Reabastecimiento',
      titleNotice: 'Comunicados',
      originSation: 'Origen',
      provider: 'Proveedor',
      potentialName: 'Potencial',
      endValidity: 'Fin de Vigencia',
      cycle: 'Ciclo',
      startDate: 'Fecha de Inicio',
      endDate: 'Fecha de Fin',
      titleServiceCode: 'Código de Servicio',
      boardingTable: 'Tablas de Abordamiento',
      stateSelectError: 'Ocurrió un error al seleccionar la estación',
      destinationSation: 'Destino',
      questionDeleteStation: '¿Está seguro de eliminar la estación?',
      generalConsultation: 'Consulta General',
      providerEdit: 'Editar proveedor',
      addProviderCode: 'Agregar código de proveedor',
      providerName: 'Nombre del Proveedor',
      addProvider: 'Agregar Proveedor',
      reference: 'Referencia',
      dateSend: 'Fecha de Envío',
      modifiedby: 'Modificado por',
      dateModified: 'Fecha de Modificación',
      category: 'Categoría',
      subcategory: 'Subcategoría',
      subject: 'Asunto',
      startValidity: 'Inicio de Vigencia',
      msgStationRequired: 'Debe seleccionar una Estación',
      msgRegionRequired: 'Debe seleccionar una Región',
      msgCodServRequired: 'Debe ingresar un Código de Servicio',
      cycleWarning: 'No hay ciclos registrados con ese provedor',
      classWarning: 'No hay clases registradas con ese provedor',

    },
    navBar: {
      equipment: 'Equipos',
      restockCap: 'Topes de Reabasto',
      irregularityCriteria: 'Criterios de Irregularidades',
      design: 'DISEÑO',
      catalogs: 'Catálogos',
      cycles: 'Ciclos',
      serviceCode: 'Código de Servicio',
      potential: 'Potenciales',
      boardingTable: 'Tablas de Abordamiento',
      generalConsultation: 'Consulta General',
      reports: 'Reportes',
      byStation: 'Por Estación',
      planning: 'PLANEACIÓN',
      fleetRelationship: 'Relación de Flota',
      master: 'Master',
      matrix: 'Matriz',
      TSU: 'TSU',
      graphics: 'Gráficos Galley',
      accommodations: 'Acomodos',
      barList: 'Lista de Bar',
      serviceGuide: 'Guía de Servicio',
      serviceGuideSisea: 'Guía de Servicio SISEA',
      materials: 'Materiales',
      irregularities: 'IRREGULARIDADES',
      statistics: 'Estadísticas',
      notices: 'COMUNICADOS',
      tracing: 'Seguimiento',
      shipping: 'Envío',
      directory: 'DIRECTORIO',
      employees: 'Empleados',
      provider: 'Proveedor',
      configuration: 'CONFIGURACIÓN',
      regions: 'Regiones',
      stations: 'Estaciones',
      users: 'Usuarios',
      welcome: '!Bienvenido (a)!',
      start: 'Inicio',
      signOff: 'Cerrar Sesión',
      questioncloseSession: '¿Está seguro que deseas salir?',
      notice: 'CONSULTA DE COMUNICADO',
      foods: 'Catálogo de Alimentos',
      manuals: 'Manuales y Procedimientos',
      products: 'Grupos',
      preselect: 'Preselect',
      preselectemails: 'Catálogo Correos Electrónicos',
      flyings: 'Catálogo Vuelos',
      stationsflying: 'Catálogo Estaciones',
      preselectioncodes: 'Catálogo Preselección',
      specialmealcodes: 'Catálogo Especial Meal',
      digitalmain: 'Menú Digítal',
      main: 'MENÚ',
      digitalMenu: 'Menu Digital'
    },
    main: {
      viewAll: 'Ver Todo',
      sessionExpired: 'Su sesión expiró',
      titleLanguage: 'Idioma',
      chooseText: 'Elija un idioma',
      languageSpanish: 'Español',
      languageEnglish: 'English',
      skip: 'Omitir',
      check: 'Revisar'
    },
    graphics: {
      uploadedFiles: 'Archivos cargados',
      loadGraphics: 'Cargar Gráficos Galley',
      loadedGraphicMsg: 'No se ha cargado ningún gráfico',
      loadedNoGraphicMsg: 'No se ha cargado ningún gráfico',
      graphicsLoadFinishMsg: 'Se terminó el proceso de carga de gráficos, revise los archivos',
      graphics: 'Gráficos Galley',
      graphicsSpecial: 'Gráficos Galley Especiales',
      addGraphics: 'Agregar Gráficos Galley',
      addGraphicsSpecial: 'Agregar Gráficos Galley Especiales',
      questionDeleteGraphic1: '¿Está seguro de eliminar el equipo',
      questionDeleteGraphic2: 'y el ',
      deleteGraphicMsg: 'Se eliminó correctamente el equipo',
      graphicEditMsg: 'Gráfico editado correctamente',
      editGraphic: 'Editar Gráfico',

      viewFileSpanish: 'Ver Archivo en Español',
      varViewFileEnglish: 'Ver Archivo en Inglés',
      varViewFile: 'Ver Archivo',
      accommodationLoad: 'Carga de Acomodos',
      fileSpanish: 'Archivo',
      fileEnglish: 'Archivo en Inglés',
      createDate: 'Fecha de Creación',
      englishDownload: 'Descarga en Inglés',
      spanishDownload: 'Descarga en Español',
      stations: 'Estaciones',
      graphsDeleteQuestion: '¿Está seguro de eliminar el grafico?',
      graphsDeleteMsg: 'Se elimino correctamente',
      graphsDeleteMsgAgain: 'No se eliminó correctamente, volver a intentar',

    },
    profile: {
      allMenu: 'TODO',
      nameProfile: 'Nombre de Perfil',
      instruccionAddProfile: 'Seleccione los permisos que desea para el modulo y/o modulos',
      permitionProfile: 'Debe seleccionar al menos un permiso',
      namePRofileAlert: 'Debe seleccionar el nombre de perfil',
      profile: 'Perfiles',
      msgDeleteProfile: 'Perfil eliminado correctamente'
    },
    codServ: {
      selectAirline: 'Debe seleccionar una aerolínea',
      airlineCode: 'Clave de Aerolínea',
      serviceCode: 'Código de Servicio',
      codeServiceDescription: 'Descripción',
      clasificationCode: 'Código de Clasificación',
      codeSISEA: 'Código SISEA',
      startDate: 'Fecha de Inicio',
      startValidityDate: 'Fecha de Inicio de Vigencia',
      process: 'Proceso',
      addServiceMsg: 'Se agregó correctamente el servicio',
      validityDateMsg: 'Debe ingresar fecha de inicio de vigencia',
      classificationMsg: 'Debe ingresar un tipo de clasificación',
      classMsg: 'Debe ingresar un tipo de clase',
      descriptionCodeServiceMsg: 'Debe ingresar una descripción del código de servicio',
      codeServiceMsg: 'Debe ingresar un código de servicio',
      serviceUpdatedSuccMsg: 'Se actualizó correctamente el servicio',
      classificationCodeMsg: 'Debe seleccionar un código de  clasificación',
      descriptionMsg: 'Debe ingresar una descripción',
      serviceDeleteMsg: 'Se eliminó correctamente el servicio',
      deleteServiceErrorMsg: 'Ocurrió un error al elminar el servicio',
      titleServiceCode: 'Código de Servicio',
      addServiceCode: 'Agregar Código de Servicio',
      editServiceCode: 'Editar código de Servicio',
      foodServiceCode: 'Código de Servicio de Alimento',
      descriptionName: 'Nombre de la Descripción',
      questionDeleteServiceCode: '¿Está seguro de eliminar el código de servicio?',
      typeService: 'Tipo de Servicio',
      validitytypeServiceMsg: 'Debe ingresar un tipo de servicio'
    },
    cycles: {
      airlineCode: 'Clave de Aerolínea',
      provider: 'Proveedor',
      process: 'Proceso',
      stateSelectError: 'Ocurrió un error al seleccionar la estación',
      selectAirline: 'Debe seleccionar una aerolínea',
      originStation: 'Origen',
      destinationStation: 'Destino',
      startDateServ: 'Fecha Inicio',
      endtDateServ: 'Fecha Fin',
      cycle: 'Ciclo',
      cycleRotationAddMsg: 'Se Agregó correctamente la rotación de ciclo',
      selectCycleMsg: 'Debe seleccionar un ciclo',
      endDateMsg: 'Debe seleccionar una fecha final',
      startDateMsg: 'Debe seleccionar una fecha de inicio',
      selectProviderMsg: 'Debe seleccionar un proveedor',
      stationOriginMsg: 'Debe seleccionar una estación origen',
      enterInitialDateMsg: 'Ingrese una  fecha inicial',
      enterEndDateMsg: 'Ingrese una  fecha final',
      cycleRotationEditMsg: 'Se editó correctamente la rotación de ciclo',
      originDestinationLinkedMsg: 'El origen y el destino no pueden ser ligados',
      destination: 'Destino',
      titleCyleRotation: 'Rotación de Ciclos',
      cycleSearch: 'Búsqueda de ciclos',
      addCycleRotation: 'Agregar rotación de ciclos',
      origin: 'Origen',
      editCycleRotation: 'Editar rotación de ciclos',
      viewAll: 'Ver Todo',
      msgCalendar: 'La fecha final debe de ser mayor a la fecha inicial',
      vardontDate: 'No encuentra la fecha de inicio o fin del servicio',
      questionDeleteCycle: '¿Está seguro de eliminar el ciclo?',
      deleteCycleErrorMsg: 'Ocurrió un error al elminar el ciclo',
      cycleDeleteMsg: 'Se eliminó correctamente el ciclo',
      addMsg: 'Agregado',
      noAddMsg: 'No agregado'
    },
    foods: {
      foods: 'Catálogo de Alimentos',
      airline: 'Aerolínea',
      article: 'Artículo',
      description: 'Descripción',
      service: 'Servicio',
      cycle: 'Ciclo',
      Option: 'Opción',
      addFood: 'Agregar Alimento',
      selectAirline: 'Debe seleccionar una aerolínea',
      msgArticle: 'Debe escribir un artículo',
      descriptionMsg: 'Debe ingresar una descripción',
      selectCycleMsg: 'Debe seleccionar un ciclo',
      selectOption: 'Debe seleccionar una opción',
      existingCode: 'Código ya existente',
      foodAdded: 'Alimento agregado correctamente',
      editMsg: 'editado correctamente',
      editFood: 'Editar Alimento',
      AddMsg: 'Se termino de procesar el archivo, verifique la tabla de alimentos cargados',
      AddMsgAgain: 'No se agregó correctamente, volver a intentar',
    },
    manuals: {
      fleetRelationship: 'Relación de Flota',
      manuals: 'Manuales y Procedimientos',
      questionManual: '¿Está seguro de eliminar el manual',
      fileDelete: 'Se elimino correctamente',
      fileEditMsg: 'Archivo editado correctamente',
      errorNomenclature: 'Nomenclatura no valida, el nombre del archivo debe comenzar con I (Inglés) o E (Español)'
    },
    master: {
      repeatPartNumberEdit: 'Este número de parte ya fue seleccionado, esta en la tabla',
      reusable: 'Reutilizable',
      msgCycle: 'Debe seleccionar un Ciclo',
      msgCommentary: 'Debe Escribir un Comentario',
      cycle: 'Ciclo',
      commentary: 'Comentario',
      dateModified: 'Inicio Vigencia',
      master: 'Máster',
      lote: 'Lote',
      description: 'Descripción',
      container: 'Contenedor',
      localStation: 'Estación Local',
      internationalStation: 'Estación Internacional',
      idMaterials: 'Id Materiales',
      supplyType: 'Tipo de Abastecimiento',
      partNumber: 'Número de Parte',
      addMaterials: 'Agregar Material',
      addKit: 'Agregar Kit',
      msgLot: 'Debe escribir un lote',
      msgDescription: 'Debe escribir una descripción',
      msgContainer: 'Debe escribir un contenedor',
      msgUm: 'Debe seleccionar una unidad de medida',
      msgLocalStation: 'Debe seleccionar una estación local',
      msgInternationalStation: 'Debe seleccionar una estación internacional',
      kitEdit: 'Editar Kit',
      supplying: 'Abastecimiento',
      materials: 'Materiales',
      kitDeleted: 'Kit Eliminado',
      questionDeleteKit: '¿Está seguro de eliminar el kit con el lote',
      selectSupply: 'Selecciona el tipo de abastecimiento',
      englishDescription: 'Descripción en Inglés',
      selectTypeSupply: 'Selecciona el tipo de abastecimiento',
      alertTypeSupply: 'Seleccione el tipo de abastecimiento',
      typeSupplyKits: 'Verifique que todos los kits contengan tipo de abastecimiento',
      kitCreated: 'Kit creado',
      familyDescription: 'Descripción Familia',
      msgDescriptionEnglish: 'Debe escribir una descripción en inglés'
    },
    providers: {
      provider: 'Proveedor',
      supplierCode: 'Código del Proveedor',
      codeStationProvider: 'Clave Estación con Proveedor',
      editProviderSucc: 'Se editó correctamente el Proveedor',
      addProviderSucc: 'Se agregó correctamente el Proveedor',
      stationNameMsg: 'Escriba la IATA  de la estación',
      deleteRegionMsg: 'Se eliminó correctamente la estación',
      titleCatalogProviders: 'Catálogo de  Proveedores',
      questionDeleteStation: '¿Está seguro de eliminar la estación?',
      providerEdit: 'Editar proveedor',
      addProviderCode: 'Agregar código de proveedor',
      providerName: 'Nombre del Proveedor',
      addProvider: 'Agregar Proveedor',
      alertCodeProvider: 'Debe escribir un código de proveedor',
      alertNameProvider: 'Debe escribir un nombre de proveedor',
      alertselectSattion: 'Debe seleccionar una estación',
      deleteProviderCorrectly: 'Se eliminó correctamente el proveedor',
      questionDeleteProvider: '¿Está seguro de eliminar el proveedor'
    },
    regions: {
      addRegion: 'Agregar Región',
      enterRegion: 'Ingrese una región',
      editRegionCorrectly: 'Se editó correctamente la región',
      editRegionAgain: 'No se editó correctamente, volver a intentar',
      deleteRegionCorrectly: 'Se eliminó correctamente la región',
      deleteRegionAgain: 'No se eliminó correctamente, volver a intentar',
      titleRegions: 'Catálogo de Regiones',
      regionName: 'Nombre de la Región',
      editRegion: 'Editar Región',
      questionDeleteRegion: '¿Está seguro de eliminar la región?'
    },
    stations: {
      stationAddMsg: 'Se agregó correctamente la estación',
      stationEditMsgAgain: 'Se editó correctamente la estación',
      stationNameMsg: 'Escriba la IATA  de la estación',
      selectRegion: 'Seleccione una Región',
      deleteRegionMsg: 'Se eliminó correctamente la estación',
      titleStations: 'Catálogo de Estaciones',
      editSation: 'Editar Estación',
      addStation: 'Agregar Estación',
      questionDeleteStation: '¿Está seguro de eliminar la estación'
    },
    users: {
      labelAll: 'Todos',
      userCatalog: 'Catálogo de Usuario',
      userAdd: 'Agregar Usuario',
      usrName: 'Nombre de Usuario',
      surnames: 'Apellidos',
      extension: 'Extensión',
      jobNameSpanish: 'Nombre del Empleo en español',
      jobNameEnglish: 'Nombre del Empleo en Inglés',
      permissions: 'Permisos',
      area: 'Área',
      provider: 'Proveedor',
      job: 'Empleo',
      addSuccUser: 'Se agrego correctamente el usuario',
      stationSelect: 'Seleccione una estación',
      profiles: 'Perfiles',
      questionDeleteUSer: '¿Está seguro de eliminar al usuario',
      userDeleteMsg: 'Se eliminó correctamente el usuario',
      deleteUserErrorMsg: 'Ocurrió un error al elminar el usuario',
      userEdit: 'Editar Usuario',
      nameUser: 'Debe ingresar un nombre de usuario',
      lastnameUser: 'Debe ingresar sus apellidos',
      claveUser: 'Debe ingresar su contraseña',
      emailUser: 'Debe ingresar su correo electrónico',
      phoneUser: 'Debe ingresar su teléfono',
      extensionUser: 'Debe ingresar su extensión',
      jobNameUserSpanish: 'Debe ingresar su nombre de empleo en español',
      jobNameUserEnglish: 'Debe ingresar su nombre de empleo en inglés',
      areaUser: 'Debe ingresar su área',
      Userprofile: 'Perfil',
      Provider: 'Proveedor',
      selectProviderMsg: 'Debe seleccionar un proveedor',
      ProfileSelect: 'Seleccione una perfil',
      EditUserCorrect: 'Usuario editado correctamente',
      selectStations: 'Seleccione las estaciones',
      labelRequired: 'Los campos obligatorios son los que están marcados con',
      titleTable: 'Tabla relacion de estaciones',
      newStation: 'Para crear una nueva relación de estaciones',
      addStations: 'Agregar nueva relación de estaciones',
      msgDeleteStation: 'Al guardar cambios, la relación de estaciones anterior será eliminada',
      click: 'Clic aquí',
      msgSelectStation: 'Debe seleccionar alguna estación',
      unasigned: 'Sin Asignar'
    },
    notice: {
      subject: 'Asunto',
      category: 'Categoría',
      subcategory: 'Subcategoría',
      destination: 'Destino',
      sentBy: 'Enviado por',
      createDate: 'Fecha de Creación',
      attachedFile: 'Archivo Adjunto',
      startValidity: 'Inicio de Vigencia',
      endValidity: 'Fin de Vigencia',
      read: 'Leído',
      unread: 'No Leído',
      userProvider: 'Usuario/Proveedor',
      stationArea: 'Estación/Área',
      noticeDelete: 'Comunicado eliminado',
      noticeUpdated: 'Comunicado actualizado',
      seeNotice: 'Ver Comunicado',
      readingStatus: 'Estatus de Lectura',
      titletConsultNotice: 'Consulta de Comunicado',
      addNotice: 'Agregar Comunicado',
      editNotice: 'Editar Comunicado',
      questionDeleteNotice: '¿Está seguro de eliminar el comunicado?',
      reference: 'Referencia',
      dateSend: 'Fecha de Envío',
      modifiedby: 'Modificado por',
      dateModified: 'Fecha de Modificación',
      titletNotice: 'Envío de Comunicado',
      addressed: 'Dirigido a',
      effectiveness: 'Efectividad',
      attach: 'Adjuntar',
      sendTo: 'Enviar a',
      firm: 'Firma',
      contactTo: 'Contactar a',
      labelAll: 'Todos',
      sentNotice: 'Comunicado Enviado',
      selectUser: 'Debe seleccionar un usuario para contactar',
      selectClass: 'Debe seleccionar una clase',
      effectiveDate: 'Debe indicar fecha de inicio de vigencia',
      selectAirline: 'Debe seleccionar una aerolínea',
      selectSubject: 'Debe indicar un asunto',
      selectSubCategory: 'Debe seleccionar una subcategoría',
      selectCategory: 'Debe seleccionar una categoría',
      msgCalendar: 'La fecha final debe de ser mayor a la fecha inicial',
      labelDestination: 'Seleccionar a quien se le enviará',
      msgEquipment: 'El campo equipo es requerido'

    },
    potentials: {
      questionDeletePotential: '¿Está seguro de eliminar el potencial',
      questionDeleteSubPotential: '¿Está seguro de eliminar el subpotencial',
      provider: 'Proveedor',
      cycle: 'Ciclo',
      titleServiceCode: 'Código de Servicio',
      revision: 'Revisión',
      potentialConsultation: 'Consulta de Potenciales',
      view: 'Ver',
      potentialLoad: 'Carga de Potenciales',
      subPotentialLoad: 'Carga de SubPotenciales',
      potentialLoadFinishMsg: 'Se terminó el proceso de carga de potenciales, revise los archivos',
      subPotentialLoadFinishMsg: 'Se terminó el proceso de carga de subPotenciales, revise los archivos',
      potentialsDeleteMsg: 'Se eliminó correctamente el Potencial',
      subPotentialsDeleteMsg: 'Se eliminó correctamente el SubPotencial',

      loadPotentials: 'Cargar Potenciales',
      loadSubPotentials: 'Cargar SubPotenciales',
      loadedPotentialMsg: 'No se ha cargado ningún potencial',
      loadedSubPotentialMsg: 'No se ha cargado ningún SubPotencial',
      uploadedFiles: 'Archivos cargados',
      invalidFile: 'Archivo Inválido',
      validFile: 'Archivo Válido',
      loadAttachments: 'Cargar Archivos'
    },
    trolleyMealDist: {
      provider: 'Proveedor',
      cycle: 'Ciclo',
      titleServiceCode: 'Código de Servicio',
      revision: 'Revisión',
      accommodations: 'Acomodos',
      acommodationsSpecials: 'Acomodos Especiales',
      viewFileSpanish: 'Ver Archivo en Español',
      varViewFileEnglish: 'Ver Archivo en Inglés',
      accommodationLoad: 'Carga de Acomodos',
      fileSpanish: 'Archivo',
      fileEnglish: 'Archivo en Inglés',
      createDate: 'Fecha de Creación',
      effectiveStart: 'Inicio de Vigencia',
      englishDownload: 'Descarga en Inglés',
      spanishDownload: 'Descarga en Español',
      loadAccommodations: 'Cargar Acomodos',
      loadAccommodationsSpecial: 'Cargar Acomodos Especiales',

      accommodationLoadFinishMsg: 'Se terminó el proceso de carga de acomodos, revise los archivos',
      loadedAccommodationMsg: 'No se ha cargado ningún Acomodo',
      trolleyDeleteQuestion: '¿Está seguro de eliminar el acomodo?',

      trolleyDeleteMsg: 'Se elimino correctamente',

      trolleyDeleteMsgAgain: 'No se eliminó correctamente, volver a intentar',

    },
    barList: {
      barList: 'Lista de Bar',
      invalidFileGraphics: 'Archivo Inválido',
      fileAdded: 'Archivo agregado correctamente'
    },
    guideServiceSisea: {
      guideServiceSisea: 'Guía de Servicio SISEA',
      invalidFileGraphics: 'Archivo Inválido',
      fileAdded: 'Archivo agregado correctamente'
    },
    updateProfile: {
      allMenu: 'TODO',
      create: 'Agregar',
      consult: 'Consultar',
      nameProfile: 'Nombre de Perfil',
      instruccionAddProfile: 'Seleccione los permisos que desea para el modulo y/o modulos',
      option: 'OPCIÓN',
      permitionProfile: 'Debe seleccionar al menos un permiso',
      namePRofileAlert: 'Debe seleccionar el nombre de perfil',
      profile: 'Perfiles',
      msgDeletePermiso: 'Se eliminó el permiso correctamente',
      msgDeleteProfile: 'Se eliminó el perfil, revisar cambios en la parte superior'
    },
    login: {
      login: 'Iniciar Sesión',
      email: 'Ingrese su correo electrónico',
      clave: 'Ingrese su password',
      recoverClave: 'Recuperar Contraseña',
      in: 'Entrar',
      msgUserOrPasswordError: 'Usuario o Password incorrectos',
      msgVerifyCredentials: 'Verificar credenciales',
      tryConnectAgain: 'Intente conectarse de nuevo',
      msgEmailvalid: 'Favor de ingresar un correo electrónico válido',
      msgSendEmailLink: 'Favor de revisar su correo para continuar con el proceso',
      emailInvalid: 'Cuenta de correo no válida',
      changeClave: 'Cambiar Contraseña',
      newClave: 'Nueva Contraseña',
      welcome: '!Bienvenido (a)!',
      accept: 'Aceptar',
      textWelcomeLogin: 'Para poder ingresar al portal por primera vez, es necesario cambiar su contraseña',
      linkExpired: 'Link expirado',
      linkInvalid: 'Link inválido',
      msgChangePassword: 'Contraseña Cambiada',
      enterNewClave: 'Ingrese su nueva contraseña',
      confirmClave: 'Confirme su contraseña',
      claveDifferents: 'Las contraseñas son diferentes',
      msgPasswordNull: 'Favor de llenar todos los campos del formulario'
    },
    products: {
      specialProducts: 'Grupos Especiales',
      products: 'Grupos',
      product: 'Grupos',
      addProduct: 'Agregar Grupo',
      productAdded: 'Grupo agregado correctamente',
      msgAddProduct: 'Debe ingresar un grupo',
      nameProduct: 'Nombre del grupo',
      questionDeleteProduct: '¿Está seguro de eliminar el grupo',
      fileDelete: 'Se elimino correctamente',
      addSpecialProduct: 'Agregar Grupo Especial',
      nameSpecialProduct: 'Nombre del Grupo especial',
      note: 'No se admite guion bajo ni espacio'

    },
    equipments: {
      equipments: 'Equipos',
      matPlane: 'Matrícula',
      shortMatPlane: 'Matrícula Corta',
      subModelType: 'Modelo',
      seatsPremier: 'Asientos Premier',
      seatsTourist: 'Asientos Turista',
      subAirplaneType: 'Sub tipo de Avión',
      family: 'Familia',
      statusODS: 'Estatus ODS',
      statusPAB: 'Estatus PAB',
      editEquipment: 'Editar Equipo',
      editEquipmentCorrectly: 'Se editó correctamente el Equipo',
      deleteEquipment: 'Se eliminó correctamente el Equipo',
      questionDeleteEquipment: '¿Está seguro de eliminar el Equipo',
    },
    irregularityCriteria: {
      irregularityCriteria: 'Criterios de Irregularidades',
      irregularityCriteriaSpanish: 'Nombre del Criterio en Español',
      irregularityCriteriaEnglish: 'Nombre del Criterio en Inglés',
      productTypeEnglish: 'Tipo de Grupo en Inglés',
      productTypeSpanish: 'Tipo de Grupo en Español',
      typeIrregularityEnglish: 'Tipo de Irregularidad en Inglés',
      typeIrregularitySpanish: 'Tipo de Irregularidad en Español',
      addirregularityCriteria: 'Agregar Criterio de Irregularidad',
      addirregularityCriteriaSucc: 'Se agregó correctamente el Criterio de Irregularidad',
      AlertTypeIrregularityEnglish: 'Debe ingresar el Tipo de Irregularidad en Inglés',
      AlertTypeIrregularitySpanish: 'Debe ingresar el Tipo de Irregularidad en Español',
      AlertProductTypeEnglish: 'Debe ingresar el Tipo de Grupo en Inglés',
      AlertProductTypeSpanish: 'Debe ingresar el Tipo de Grupo en Español',
      AlertIrregularityCriteriaEnglish: 'Debe ingresar el Nombre de Criterio en Inglés',
      AlertIrregularityCriteriaSpanish: 'Debe ingresar el Nombre de Criterio en Español',
      questionDeleteCriteria: '¿Está seguro de eliminar el Criterio de Irregularidad',
      deleteIrregularityCriteria: 'Se eliminó correctamente el Criterio de Irregularidad',
      editIrregularityCriteria: 'Editar Criterio de Irregularidad',
      editIrregularityCriteriaCorrectly: 'Se editó correctamente el Criterio de Irregularidad',
      addCriteria: 'Agregar Criterio',
      addArea: 'Agregar Área',
      nameAreaSpanish: 'Nombre del Área en Español',
      nameAreaEnglish: 'Nombre del Área en Inglés',
      alertNameAreaSpanish: 'Debe ingresar el nombre del área en español',
      alertNameAreaEnglish: 'Debe ingresar el nombre del área en inglés',
      area: 'Área',
      editArea: 'Editar área',
      editAreaSucces: 'Se editó corectamente el área',
      addAreaSucc: 'Se agregó correctamente el área',
      deleteArea: 'Se eliminó correctamente el área',
      questionDeleteArea: '¿Está seguro de eliminar el Área',
      nameIrregularityCriteria: 'Nombre Del Criterio',
      nameProductType: 'Tipo de Grupo',
      nameIrregularityType: 'Tipo de Irregularidad',

      filter: 'Filtro',
      labelResponsable: 'Responsable',
      labelDirectBoss: 'Jefe Directo',
      labelSelectResponsableValid: 'Seleccione un responsable o valido, un area no puede ser seleccionada como responsable ',
      labelSelectDirectBossValid: 'Seleccione un jefe directo valido, un área no puede ser seleccionada como jefe directo',
      invalidFilter: 'Filtro inválido, este campo es para filtrar, no ingresar emails en este campo, para seleccionar despliegue el listado de usuarios que se encuentra abajo',
      labelAlertResponsable: 'Debe seleccionar un responsable',
      labelAlertDirectBoss: 'Debe seleccionar un jefe directo',
      labelStation: 'Debe seleccionar un estacion',
      labelOnlyOneResponsable: 'Solo puedes seleccionar un responsable',
      labelOnlyOneDirectBoss: 'Solo puedes seleccionar un jefe directo',
      stations: 'Estaciones',
      labelDontResponsableAndDirectBoss: 'No hay responsable ni jefe directo asignado para esta estación, asigne una manualmente',


    },
    usrConfig: {
      email:'Correo Electrónico',
      clave: 'Nueva Contraseña',
      newClave: 'Confirmar Contraseña',
      name: 'Nombre',
      surnames: 'Apellidos',
      telephone: 'Teléfono',
      extension: 'Extensión',
      profile: 'Perfil',
      area: 'Área',
      charge: 'Empleo',
      moduleTitle: 'Configuración',
      sectionTitleUsr: 'Información General',
      sectionTitlePass: 'Actualizar Contraseña',
      msgRequiredField: 'El campo es requerido',
      msgMinLength: 'La contraseña debe tener al menos 14 caracteres',
      msgMaxLength: 'La contraseña no debe exceder los 20 caracteres',
      msgMatching: 'La contraseña no coincide',
      msgPattern: 'La contraseña debe contener una mayúscula, un número y un carácter especial(.,*!?¿¡/#$%&)',
      btnUpdatePass: 'Actualizar',
      msgUpdatePass: 'La contraseña se actualizó correctamente'
    },
    digitalMenu: {
      //Titles
      titleModule: 'Menu Digital',
      Name:'Nombre',
      CreadoPor:'Creado Por',
      FechaDeCarga:'Fecha de Carga',
      Id:'id',
      Status: 'Estatus',
      uploadFile: 'Cargar Archivo',
      msgUpload: 'Arrastre y suelte sus archivos aquí',
      msgUploadOr: 'o',
      btnBrowseFile: 'Seleccionar Archivo',
      nameTableFile: 'Nombre del archivo',
      FilesWarning:'Se deben de cargar 4 archivos de alimentos y 4 de bebidas.',
      Active: "Activo",
      Inactive: "Inactivo",
      AddMsg: 'Se cargaron correctamente los archivos de Menú digital.',
      ValidFiles:'Archivos validados, inicia proceso de carga.',
      MmodalDTitle: "Descargar archivos zip",
      SelectDTitle: "Seleccione el tipo de archivo que desea descargar",
      SelectDOption1: "Reporte archivos CSV",
      SelectDOption2: "Imagen resolución 1024X600",
      SelectDOption3: "Imagen resolución 1368X768",
      SelectDOption4: "Imagen resolución 1920X1080",
      SelectDError: "Debe seleccionar un tipo de archivo a descargar",
      UploadFile: "Cargar archivos"
    },
  },
  global: {
    editRegionAgain: 'No se editó correctamente, volver a intentar',
    seeNotice: 'Ver Comunicado',
    english: 'English',
    spanish: 'Español',
    enter: 'Entrar',
    phone: 'Teléfono',
    email: 'Correo Electrónico',
    clave: 'Contraseña',
    editAgain: 'No se editó correctamente, volver a intentar',
    export: 'Exportar',
    msgPicture: 'Debe ingresar una imagen',
    shortDescription: 'Descripción Corta',
    quantity: 'Cantidad',
    descriptionEnglish: 'Descripción en Inglés',
    picture: 'Imagen',
    effectiveDate: 'Fecha de Vigencia',
    view: 'Ver',
    editFile: 'Editar Archivo',
    addFile: 'Agregar Archivo',
    fileAdded: 'Archivo agregado correctamente',
    author: 'Autor',
    varDate: 'Fecha',
    name: 'Nombre',
    download: 'Descargar',
    search: 'Buscar',
    to: 'Hasta',
    from: 'Desde',
    individualLoad: 'Carga Individual',
    bulkLoad: 'Carga Masiva',
    fileUpload: 'Cargar Archivo',
    airline: 'Aerolínea',
    message: 'Mensaje',
    description: 'Descripción',
    correct: 'Correcto',
    incorrect: 'Incorrecto',
    class: 'Clase',
    close: 'Cerrar',
    station: 'Estación',
    region: 'Región',
    option: 'OPCIÓN',
    submenu: 'SUBMENU',
    menu: 'Menú',
    consult: 'Consultar',
    verifyFileMsg: 'Verifique que el archivo sea correcto',
    notEditedCorrectly: 'No se editó correctamente, volver a intentar',
    invalidFileMsg: 'Archivo inválido, verifique los campos obligatorios y el formato del archivo',
    notDeleteSuccess: 'No se eliminó correctamente, volver a intentar',
    noFileSelected: 'Ningún Archivo Seleccionado',
    uploadFile: 'Cargar Archivo',
    file: 'Archivo',
    products: 'Grupos',
    product: 'Grupo',
    equipment: 'Equipo',
    notAddedCorrectly: 'No se agregó correctamente, volver a intentar',
    dragFilesUpload: 'Arrastre los archivos que desea cargar aquí',
    deleteFilesMsg: 'Eliminar Archivos Seleccionados',
    verifyFileNomenclatureMsg: 'Verifique que todos sus archivos tengan la nomenclatura correcta',
    fileSelectMsg: 'No se Seleccionó Ningún archivo',
    invalidFile: 'Archivo Inválido',
    validFile: 'Archivo Válido',
    fileName: 'Nombre del Archivo',
    add: 'Agregar',
    next: 'Siguiente',
    back: 'Atras',
    browse: 'Buscar',
    edit: 'Editar',
    delete: 'Eliminar',
    send: 'Enviar',
    detail: 'Detalle',
    create: 'Crear',
    adding: 'Agregando...',
    editing: 'Editando...',
    deleting: 'Eliminando...',
    sending: 'Enviando...',
    save: 'Guardar',
    clearImage: 'Limpiar Imagen',
    cancel: 'Cancelar',
    confirm: 'Confirmar',
    globalFilter: 'FILTRO GLOBAL',
    sessionExpired: 'Su sesión expiró',
    successful: 'Exitoso',
    warning: 'Advertencia',
    sentNotice: 'Comunicado enviado',
    tryAgain: 'Favor de intentarlo más tarde',
    connectionError: 'Error de conexión',
    loading: 'Cargando...',
    valueLanguge: 'Español',
    status: 'Estatus',
    yes: 'Si',
    active: 'ACTIVO',
    inactive: 'INACTIVO',
    login: 'Iniciando Sesión...',
    error: 'Error',
    filenotfound: 'El archivo no existe o aún no ha sido cargado / actualizado.',
    date: {
      valueDayNames: ["Domingo", "Lunes", "MArtes", "Miércoles", "Jueves", "Viernes", "Sábado"],
      valueDayNamesShort: ["Dom", "Lun", "Mar", "Mier", "Jue", "Vi", "Sa"],
      valueDayNamesMin: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"],
      valueMonthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
      valueMonthNamesShort: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
      valueToday: 'Hoy',
      valueClear: 'Borrar'
    },
    titlePdf: 'Pdf',
    pdf: 'Ver',
    downloadPdf: 'Descargar Pdf',
    excel: 'Descargar Excel',
    allMenu: 'todo',
    loadedAttached: 'No se ha cargado ningún archivo',
    loadFile: 'Cargar Archivo',
    attachedFiles: 'Archivos adjuntos',
    uploadedFiles: 'Archivos cargados',
    loadAttachments: 'Cargar Archivos',
    formatContra:'Contraseña incorrecta: Debe contener una Mayúscula, un número, un caracter especial y una longitud de al menos 14 caracteres',
    claveInvalid:'La contraseña no puede ser la misma que la anterior.',
    fileNotFound: 'El archivo no fue encontrado o no existe, informe a su Administrador del portal',
    //Propiedades de breadcrumb
    breadcrumbInicio: 'Inicio'
  }

};
