<block-ui>
  <div class="container-fluid">
    <!-- BANNER CON TITULO -->
    <div class="row divTitle">
      <section [defaultImage]="defaultImage" [lazyLoad]="image" class="titleCat titleRestockCap">
        <h1 class="titleComponent">{{languageModule.titleModule}}</h1>
      </section>
    </div>
    <div class="containerRestockCap">
      <p-table [columns]="cols" [value]="restockCap" [paginator]="true" [rows]="5" #dtt dataKey="IdRestockCap"
        [resizableColumns]="true" [reorderableColumns]="true" [rowsPerPageOptions]="[5,10,20]">
        <ng-template pTemplate="caption">
          <!-- FILTRO GLOBAL -->
          <div style="text-align: left;">
            <input class="containerFiltro uppercaseSearch" type="text" pInputText size="50"
              placeholder={{languageGlobal.globalFilter}} (input)="dtt.filterGlobal($event.target.value, 'contains')"
              style="width:auto">
          </div>
          <!-- BOTTON DE AGREGAR -->
          <div class="buttonAddGral ButtonAdd" *ngIf="canCreate">
            <button type="button" class="btn-options styleButtonTable" (click)="openAddModal()"><i
                class="fas fa-plus-circle"></i>&nbsp; {{languageGlobal.add}}</button>
          </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th class="headertable" *ngFor="let col of columns" [pSortableColumn]="col.field" pResizableColumn
              pReorderableColumn>
              {{col.header}}
              <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
            <th class="headertable" *ngIf="canDelete">{{languageGlobal.delete}}</th>
            <th class="headertable" *ngIf="canRead">{{languageGlobal.pdf}}</th>
            <th class="headertable" *ngIf="canRead">
              {{languageGlobal.excel}}</th>
          </tr>
          <tr>
            <th *ngFor="let col of columns" [ngSwitch]="col.field" class="ui-fluid styleTable uppercase center">
              <input pInputText type="text" class="uppercaseSearch"
                (input)="dtt.filter($event.target.value, col.field, 'contains')"
                [value]="$any(dtt.filters[col.field])?.value">
            </th>
            <th class="styleTable" *ngIf="canDelete"></th>
            <th class="styleTable" *ngIf="canRead"></th>
            <th class="styleTable" *ngIf="canRead"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-c let-columns="columns">
          <tr>
            <td *ngFor="let col of columns" class="styleTable uppercase center">
              <span *ngIf="col.field === 'dateEnd' || col.field === 'dateInit'"> -->
                {{c[col.field] | dates: '' }}
              </span>
              <span *ngIf="col.field !== 'dateEnd' &&  col.field !== 'dateInit'">
                {{c[col.field] }}
              </span>

            </td>
            <td *ngIf="canDelete" class="styleTable">
              <button type="button" class="btn-options styleButtonTable" (click)="deleteModal(c)">
                <i class="fas fa-trash-alt"></i>
              </button>
            </td>
            <td *ngIf="canRead" class="styleTable">
              <a href="javascript:" type="button" class="btn-options styleButtonTable" (click)="pdfViewer(c)">
                <i class="fas fa-file-pdf"></i>
              </a>
            </td>
            <td *ngIf="canRead" class="styleTable">
              <button type="button" class="btn-options styleButtonTable" (click)="downloadExcel(c)">
                <i class="fas fa-file-excel"></i>
              </button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</block-ui>

<!-- MODAL DE AGREGAR -->
<div class="modal fade" id="addModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true"
data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{languageModule.titleAdd}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModalAdd()">
          <i class="fa fa-times"></i>
        </button>
      </div>
      <div class="modal-body modal-add-config">
        <br /><br />
        <div class="input-group mb-3">
          <form [formGroup]="formulario" style="width: 100%;">
            <div class="input-group custom-file-button">
                <label style="background-color: #ffffff;" for="inputGroupFile01" class="form-control" id="image-label" role="button">{{getNameFileImage(false)}}</label>
                <label class="input-group-text" [ngClass]="{'image-error' : !validExcel}"
                for="inputGroupFile01" role="button">Browse</label>

                <input formControlName="potentialFile" type="file" class="d-none form-control" id="inputGroupFile01"
                aria-describedby="inputGroupFileAddon01" placeholder="{{getNameFileImage(false)}}"
                (change)="changeListener($event, 1)">
            </div>
          </form>
        </div>
        <br />
      </div>
      <div class="modal-footer">
        <button type="button" class="btn-options styleButtonTable" data-dismiss="modal" (click)="Add()"><i
            class="fas fa-plus-circle"></i>&nbsp; {{languageGlobal.add}}</button>
      </div>
    </div>
  </div>
</div>

<!--Modal Delete-->
<div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{languageModule.DeleteQuestion}}
        </h5>
        <button type="button" class="close" data-dismiss="modal" (click)="closeModal('#deleteModal')" aria-label="Close">
          <i class="fa fa-times"></i>
        </button>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn-options styleButtonTable buttonYes" data-dismiss="modal"
          (click)="delete()">{{languageGlobal.yes}}</button>
        <button type="button" class="btn-options styleButtonTable" (click)="closeModal('#deleteModal')" data-dismiss="modal">No</button>
      </div>
    </div>
  </div>
</div>
