import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import {APP_BASE_HREF, CommonModule, DatePipe} from '@angular/common';
import { LazyLoadImageModule } from 'ng-lazyload-image'; // <-- import it

/*Componentes */
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent} from '../app/Components/login/login.component';
import { NavbarLoguedComponent } from './Components/share/navbar-logued/navbar-logued.component';
import { MainComponent } from './Components/main/main.component';
import { ConsultNoticeComponent } from './Components/consult-notice/consult-notice.component';
import { UpdateProfileComponent } from './Components/update-profile/update-profile.component';
import { CatMasterComponent } from './Components/cat-master/cat-master.component';
import { FormularioMasterComponent } from './Components/formulario-master/formulario-master.component';

/*Utils */
import { ToastrModule } from 'ngx-toastr';
import { BlockUIModule } from 'ng-block-ui';
import { JwtHelperService } from '@auth0/angular-jwt';
import {MultiSelectModule} from 'primeng/multiselect';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

/*Angular material */
import {MatIconModule,} from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import {MatListModule} from '@angular/material/list';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import {MatDialogModule} from '@angular/material/dialog';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';


/*Pipes*/
import { PipesModule } from './Pipes/pipes.module';
import { RestockModule } from './Components/restock-cap/restock-cap.module';
import { FilterPipe } from './Components/master/filter.pipe';
import { AccountSettingsComponent } from './Components/share/account-settings/account-settings.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NavbarLoguedComponent,
    FilterPipe,
    AccountSettingsComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    FormsModule,
    MultiSelectModule,
    HttpClientModule,
    MatIconModule,
    MatToolbarModule,
    MatListModule,
    BlockUIModule.forRoot(),
    ToastrModule.forRoot(),
    LazyLoadImageModule,
    PipesModule,
    BrowserAnimationsModule,
    RestockModule,
    MatSidenavModule,
    MatExpansionModule,
    MatMenuModule,
    MatButtonModule,
    MatSelectModule,
    MatDialogModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
  ],
  providers: [
    JwtHelperService,
    MainComponent,
    NavbarLoguedComponent,
    UpdateProfileComponent,
    ConsultNoticeComponent,
    FormularioMasterComponent,
    CatMasterComponent,
    DatePipe,

    { provide: APP_BASE_HREF, useValue: '/' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
