import { Component, OnInit, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { formatDate } from '@angular/common';
import { NoticeService } from '../../Services/notice.service';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription } from "rxjs";
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { NavbarLoguedComponent } from '../share/navbar-logued/navbar-logued.component';
import { PermissionsService } from 'src/app/Services/permissions.service';
import { LoginService } from 'src/app/Services/Login.service';
import { LanguageService } from 'src/app/Services/language.service';
import { FilesService } from 'src/app/Services/files.service';
declare var $;

declare global {
  interface Blob {
    saveFile(fileName: string): void;
  }
}
Blob.prototype.saveFile = function (fileName: string) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(this);
    a.href = url;
    a.download = fileName;
    a.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, 0);
};

@Component({
  selector: 'app-consult-notice',
  templateUrl: './consult-notice.component.html',
  styleUrls: ['./consult-notice.component.css']
})
export class ConsultNoticeComponent implements OnInit, OnDestroy {
  @ViewChild('pdfview', { static: false }) pdfview: ElementRef<HTMLInputElement> = {} as ElementRef;
  private subscription: Subscription = new Subscription();
  // Variables permisos --------------
  public canCreate;
  public canRead;
  public canUpdate;
  public canDelete;
  //----------------------------------
  @BlockUI() blockUI: NgBlockUI;
  public hasBaseDropZoneOver: boolean = false;
  private filesSubject: Subject<File>;

  arrayBuffer: any;
  file: File;
  public allData: any;
  public cols: any[];
  public consultNotice: any[];
  public idUser: number;
  public en: any;
  public consultNoticeDisplay: any[];
  public colsDisplay: any[];

  //ADD
  public upLoadFile: any;
  public calendarInitAdd: Date;
  public codServeTableAdd: any;
  public colsServeTableAdd: any[];
  public idSelectedAirline: string;
  public nameCodServ: string;
  public description: string;
  public idSelectedclassAdd: string;
  public idSelectedclassificationAdd: string;
  //SELECT
  public airlineSelect: any;
  public classSelect: any;
  public classificationSelect: any;
  public selectStatusEdit: any;

  //EDIT
  public nameCodServEdit: string;
  public idCodServEdit: number;
  public descriptionEdit: string;
  public idSelectedAirlineEdit: string;
  public selectClassEdit: string;
  public classificationEdit: string;
  public calendarInitEdit: Date;
  public statusEdit: string;
  public AsuntoEdit: string;
  public nameAdjuntoEdit: string;
  public idStatus: number;
  public idUserEdit: number;
  public routePDF: string;

  //Variables Idioma Alertas
  public varLanguage: string
  public varSessionExpired: string
  public varSuccessful: string
  public varWarning: string
  public varSentNotice: string
  public varTryAgain: string
  public varConnectionError: string
  public varLoading: string
  public varLocalStorage: string
  public varSubject: string
  public varCategory: string
  public varSubcategory: string
  public varDescription: string
  public varClass: string
  public varDate: string


  public varActive: string
  public varInactive: string
  public varId: string = 'Id'
  public varAirline: string
  public varDestination: string
  public varSentBy: string
  public varCreateDate: string
  public varAttachedFile: string
  public varStartValidity: string
  public varEndValidity: string
  public varStatus: string
  public varRead: string
  public varUnread: string
  public varEmail: string
  public varUserProvider: string
  public varStationArea: string
  public varNoticeDelete: string
  public varNoticeUpdated: string
  public varAdd: string
  public varEdit: string
  public varDelete: string
  public varSeeNotice: string
  public varReadingStatus: string
  public varFile: string
  public varTitletConsultNotice: string
  public varAddNotice: string
  public varEditNotice: string
  public varQuestionDeleteNotice: string
  public varSave: string
  public varYes: string
  public varClose: string
  public varGlobalFilter: string
  public valueDescription: string
  public valueCategory: string
  public valueSubcategory: string
  public valueAreaStation: string
  public valueDayNames: any[]
  public valueDayNamesShort: any[]
  public valueDayNamesMin: any[]
  public valueMonthNames: any[]
  public valueMonthNamesShort: any[]
  public valueToday: string
  public valueClear: string
  public reference: string
  public dateSend: string;
  public modifiedby: string;
  public dateModified: string;
  public badgeCount: number;
  public consultRead: number
  public idNoticeEdit: string
  public varFileNotFound: string

  // Variable que se utiliza para descargar el archivo pdf
  blob: any;

  defaultImage = '../../assets/images/compress/consultNotice.jpg';
  image = '../../assets/images/consultNotice.jpg';
  public language;
  public languageGlobal;
  public languageModule;
  extensionsPdf = ['pdf'];
  extensionsWord = ['docx', 'docm', 'dotx', 'dotm', 'txt'];
  extensionsExcel = ['xlsx', 'xlsm', 'xltx', 'xltm', 'xlsb', 'xlam', 'xls'];
  extensionsImage = ['jpeg', 'jpg', 'png', 'gif', 'tiff', 'psd', 'eps', 'ai', 'indd', 'raw'];
  extensionsVideo = ['mpeg-4', 'divx', 'mpeg-2', 'hevc', 'mp4', 'avi', 'mov', 'flv', 'wmv'];
  extensionsPowerPoint = ['pptx', 'pptm', 'potx', 'potm', 'ppam', 'ppsx', 'ppsm', 'ppsm', 'sldx', 'sldm', 'thmx'];
  constructor(private noticeService: NoticeService, private toastr: ToastrService, private router: Router, private languagueService: LanguageService,
    private NavbarLoguedComponent: NavbarLoguedComponent,
    private permissionsService: PermissionsService,
    private loginService: LoginService,
    private filesService: FilesService,
  ) {

    this.language = languagueService.getLanguage();
    this.languageGlobal = this.language.global;
    this.languageModule = this.language.modules.notice;

    this.languagueService.changeLanguage.subscribe(
      English => {
        this.language = languagueService.getLanguage();
        this.languageGlobal = this.language.global;
        this.languageModule = this.language.modules.notice;
        this.getVariables();
        this.valueLanguge();
        this.getColumns();
      }
    )

    this.canCreate = permissionsService.CanSubMenu(4, 20, 'C');
    this.canRead = permissionsService.CanSubMenu(4, 20, 'R');
    this.canUpdate = permissionsService.CanSubMenu(4, 20, 'U');
    this.canDelete = permissionsService.CanSubMenu(4, 20, 'D');

    /*      this.canCreate = true;
         this.canRead = true;
         this.canUpdate = true;
         this.canDelete = true; */
  }


  ngOnInit() {
    this.varLocalStorage = localStorage.getItem('language')
    this.valueLanguge();
    this.getVariables()

    let uno = localStorage.getItem('token')
    let personal = this.loginService.getUser();
    this.idUser = parseInt(personal.idUser.toString())
    this.getData(this.idUser)
    this.formatCalendar()
    this.notificationsFunction();

  }

  valueLanguge() {
    if (localStorage.getItem('language') === 'true') {
      this.varLanguage = 'Ingles'
    } else if (localStorage.getItem('language') === 'false') {
      this.varLanguage = 'Español'
    }
  }

  getVariables() {
    this.varSessionExpired = this.languageGlobal.sessionExpired;
    this.varSuccessful = this.languageGlobal.successful;
    this.varWarning = this.languageGlobal.warning;
    this.varTryAgain = this.languageGlobal.tryAgain;
    this.varConnectionError = this.languageGlobal.connectionError;
    this.varLoading = this.languageGlobal.loading;
    this.varSubject = this.languageModule.subject;
    this.varCategory = this.languageModule.category;
    this.varSubcategory = this.languageModule.subcategory;
    this.varDescription = this.languageGlobal.description;
    this.varClass = this.languageGlobal.class;
    this.varDate = this.languageGlobal.varDate;
    this.varSuccessful = this.languageGlobal.successful;
    this.varActive = this.languageGlobal.active;
    this.varInactive = this.languageGlobal.inactive;
    this.varAirline = this.languageGlobal.airline;
    this.varDestination = this.languageModule.destination;
    this.varSentBy = this.languageModule.sentBy;
    this.varCreateDate = this.languageModule.createDate;
    this.varAttachedFile = this.languageModule.attachedFile;
    this.varStartValidity = this.languageModule.startValidity;
    this.varEndValidity = this.languageModule.endValidity;
    this.varStatus = this.languageGlobal.status;
    this.varRead = this.languageModule.read;
    this.varUnread = this.languageModule.unread;
    this.varEmail = this.languageGlobal.email;
    this.varUserProvider = this.languageModule.userProvider;
    this.varStationArea = this.languageModule.stationArea;
    this.varNoticeDelete = this.languageModule.noticeDelete;
    this.varNoticeUpdated = this.languageModule.noticeUpdated;
    this.varAdd = this.languageGlobal.add;
    this.varDelete = this.languageGlobal.delete;
    this.varEdit = this.languageGlobal.edit;
    this.varSeeNotice = this.languageModule.seeNotice;
    this.varReadingStatus = this.languageModule.readingStatus;
    this.varFile = this.languageGlobal.file;
    this.varTitletConsultNotice = this.languageModule.titletConsultNotice;
    this.varAddNotice = this.languageModule.addNotice;
    this.varEditNotice = this.languageModule.editNotice;
    this.varQuestionDeleteNotice = this.languageModule.questionDeleteNotice;
    this.varSave = this.languageGlobal.save;
    this.varYes = this.languageGlobal.yes;
    this.varClose = this.languageGlobal.close;
    this.varGlobalFilter = this.languageGlobal.globalFilter;
    this.reference = this.languageModule.reference;
    this.dateSend = this.languageModule.dateSend;
    this.modifiedby = this.languageModule.modifiedby;
    this.dateModified = this.languageModule.dateModified;
    this.varFileNotFound = this.languageGlobal.fileNotFound;
  }

  formatCalendar() {
    if (this.varLanguage === 'Ingles') {
      this.valueDayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      this.valueDayNamesShort = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
      this.valueDayNamesMin = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"]
      this.valueMonthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
      this.valueMonthNamesShort = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
      this.valueToday = 'Today'
      this.valueClear = 'Clear'
    } else if (this.varLanguage === 'Español') {
      this.valueDayNames = ["Domingo", "Lunes", "MArtes", "Miércoles", "Jueves", "Viernes", "Sábado"]
      this.valueDayNamesShort = ["Dom", "Lun", "Mar", "Mier", "Jue", "Vi", "Sa"]
      this.valueDayNamesMin = ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"]
      this.valueMonthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]
      this.valueMonthNamesShort = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"]
      this.valueToday = 'Hoy'
      this.valueClear = 'Borrar'
    }
    this.en = {
      firstDayOfWeek: 0,
      dayNames: this.valueDayNames,
      dayNamesShort: this.valueDayNamesShort,
      dayNamesMin: this.valueDayNamesMin,
      monthNames: this.valueMonthNames,
      monthNamesShort: this.valueMonthNamesShort,
      today: this.valueToday,
      clear: this.valueClear,
      dateFormat: 'dd/mm/yy',
      weekHeader: 'Wk'
    };
  }

  isLogued() {
    if (localStorage.getItem('logued') === 'true') {
      return true;
    } else {
      return false
    }

  }

  getColumns() {
    this.cols = [
      { field: 'IdNotice', header: 'No.' },
      { field: 'Referencia', header: this.reference },
      { field: 'FechaCreacion', header: this.dateSend },
      { field: 'Categoria', header: this.varCategory },
      { field: 'Subcategoria', header: this.varSubcategory },
      { field: 'Asunto', header: this.varSubject },
      { field: 'InicioVigencia', header: this.varStartValidity },
      { field: 'FinVigencia', header: this.varEndValidity },
      { field: 'Estatus', header: this.varStatus },
      { field: 'ModificadoPor', header: this.modifiedby },
      { field: 'FechaModifcado', header: this.dateModified },
      { field: 'Adjunto', header: this.varAttachedFile },
    ];

    if (this.isLogued() && this.router.url === '/consultNotice') {
      this.getData(this.idUser)
    }

  }
  getData(idUser) {
    this.blockUI.start(this.varLoading);
    this.subscription.add(this.noticeService.getNotice(this.idUser).subscribe((data: any) => {
      let status = 0;
      status = data.ClassInfo.Status
      if (status == 200) {
        this.blockUI.stop();
        this.getTableData(data)
      }
    }, error => {
      if (error.status === 401 || error.status === 0) {
        this.blockUI.stop();
        this.toastr.error(this.varSessionExpired, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
        localStorage.setItem('logued', 'false');
        this.router.navigate(['/login']);
      } else if (error.status === 404) {
        this.blockUI.stop();
        this.toastr.error(this.varTryAgain, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
      } else if (error.status === 500) {
        this.blockUI.stop();
        this.toastr.error(this.varConnectionError, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
      }
    }));
    this.blockUI.stop();
  }

  getTableData(data: any) {


    this.allData = data
    this.consultNotice = []
    let consultNoticeTable = data.sendNoticeBeans;


    let lengthconsultNotice = consultNoticeTable.length
    for (let i = 0; i < lengthconsultNotice; i++) {
      let fechaCreacion;
      let initVigencia;
      let endVigencia;
      fechaCreacion = formatDate(consultNoticeTable[i].fechaCreacion, 'dd/MM/yyyy', 'en');
      initVigencia = formatDate(consultNoticeTable[i].dateInit, 'dd/MM/yyyy', 'en');
      if (consultNoticeTable[i].dateEnd !== null) {
        endVigencia = formatDate(consultNoticeTable[i].dateEnd, 'dd/MM/yyyy', 'en');
      }

      let status = ""
      if (consultNoticeTable[i].status == 1) {
        status = this.varActive
      } else {
        status = this.varInactive
      }
      if (this.varLanguage === 'Ingles') {
        this.valueDescription = consultNoticeTable[i].descriptionEnglish
        this.valueCategory = consultNoticeTable[i].categoryEnglish
        this.valueSubcategory = consultNoticeTable[i].subCategoryEnglish
      } else if (this.varLanguage === 'Español') {
        this.valueDescription = consultNoticeTable[i].descriptionSpanish
        this.valueCategory = consultNoticeTable[i].category
        this.valueSubcategory = consultNoticeTable[i].subCategory
      }
      this.consultNotice.push({
        IdNotice: consultNoticeTable[i].idNotice,
        Referencia: consultNoticeTable[i].reference,
        FechaCreacion: consultNoticeTable[i].fechaCreacion,
        FechaCreacionFormat: fechaCreacion,
        Categoria: this.valueCategory,
        Subcategoria: this.valueSubcategory,
        Asunto: consultNoticeTable[i].subject,
        InicioVigencia: consultNoticeTable[i].dateInit,
        InicioVigenciaFormat: initVigencia,
        FinVigencia: consultNoticeTable[i].dateEnd,
        FinVigenciaFormat: endVigencia,
        Estatus: status,
        ModificadoPor: consultNoticeTable[i].modificadoPor,
        FechaModifcado: consultNoticeTable[i].fechadeModificacion,
        Adjunto: consultNoticeTable[i].typeAttachedFile,
        readNotice: consultNoticeTable[i].readNotice,
        attachedFile: consultNoticeTable[i].attachedFile,

      })

    }
    this.cols = [
      { field: 'IdNotice', header: 'No.' },
      { field: 'Referencia', header: this.reference },
      { field: 'FechaCreacion', header: this.dateSend },
      { field: 'Categoria', header: this.varCategory },
      { field: 'Subcategoria', header: this.varSubcategory },
      { field: 'Asunto', header: this.varSubject },
      { field: 'InicioVigencia', header: this.varStartValidity },
      { field: 'FinVigencia', header: this.varEndValidity },
      { field: 'Estatus', header: this.varStatus },
      { field: 'ModificadoPor', header: this.modifiedby },
      { field: 'FechaModifcado', header: this.dateModified },
      { field: 'Adjunto', header: this.varAttachedFile },
    ];

  }



  getTableDisplay(data: any) {
    this.getData(this.idUser)
    this.searchId(data)
    $('#getTableDisplay').modal('show');
    $("body").removeAttr("style");
    this.blockUI.start(this.varLoading);
    this.subscription.add(this.noticeService.getReadNotice(data.IdNotice).subscribe((data: any) => {
      let status = 0;
      status = data.ClassInfo.Status
      if (status == 200) {
        this.blockUI.stop();
        this.consultNoticeDisplay = []
        let consultNoticeTableDisplay = data.readNotice;
        let lengthconsultNoticeDisplay = consultNoticeTableDisplay.length
        for (let i = 0; i < lengthconsultNoticeDisplay; i++) {
          let init;
          init = formatDate(consultNoticeTableDisplay[i].dateRead, 'dd/MM/yyyy', 'en');
          let status = ""
          let validationDate
          if (consultNoticeTableDisplay[i].status == 1) {
            status = this.varRead
            validationDate = init
          } else {
            status = this.varUnread
            validationDate = ''
          }
          if (this.varLanguage === 'Ingles') {
            this.valueAreaStation = consultNoticeTableDisplay[i].areaStationEnglish
          } else if (this.varLanguage === 'Español') {
            this.valueAreaStation = consultNoticeTableDisplay[i].areaStation
          }
          this.consultNoticeDisplay.push({
            areaStation: this.valueAreaStation,
            userName: consultNoticeTableDisplay[i].userName,
            email: consultNoticeTableDisplay[i].email,
            date: validationDate,
            Status: status,
          })
        }
        this.colsDisplay = [
          { field: 'areaStation', header: this.varStationArea },
          { field: 'userName', header: this.varUserProvider },
          { field: 'email', header: this.varEmail },
          { field: 'date', header: this.varDate },
          { field: 'Status', header: this.varStatus }
        ];
      }
    }, error => {
      if (error.status === 401 || error.status === 0) {
        this.blockUI.stop();
        this.toastr.error(this.varSessionExpired, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
        localStorage.setItem('logued', 'false');
        this.router.navigate(['/login']);
      } else if (error.status === 404) {
        this.blockUI.stop();
        this.toastr.error(this.varTryAgain, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
      } else if (error.status === 500 || error.status === 400) {
        this.blockUI.stop();
        this.toastr.error(this.varConnectionError, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
      }
    }));
  }

  searchId(data: any) {
    let lengthSendNotice = this.allData.sendNoticeBeans.length
    for (let i = 0; i < lengthSendNotice; i++) {
      if (this.allData.sendNoticeBeans[i].category == data.Categoria) {
        if (this.allData.sendNoticeBeans[i].classP == data.Clase) {
          if (this.allData.sendNoticeBeans[i].codeAirLine == data.Aerolinea) {
            let createNotice = formatDate(this.allData.sendNoticeBeans[i].fechaCreacion, 'dd/MM/yyyy', 'en');
            if (createNotice == data.FechaCreacion) {
              if (this.allData.sendNoticeBeans[i].subCategory == data.Subcategoria) {
                if (this.allData.sendNoticeBeans[i].subject == data.Asunto) {
                  if (this.allData.sendNoticeBeans[i].destination == data.Destino) {
                    if (this.allData.sendNoticeBeans[i].enviadoPor == data.Enviado) {
                      let init = formatDate(this.allData.sendNoticeBeans[i].dateInit, 'dd/MM/yyyy', 'en');
                      if (init == data.InicioVigencia) {
                        let end = formatDate(this.allData.sendNoticeBeans[i].dateEnd, 'dd/MM/yyyy', 'en');
                        if (end == data.FinVigencia) {
                          this.idUserEdit = this.allData.sendNoticeBeans[i].idNotice;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  UpdateModal(data: any) {
    this.idNoticeEdit = data.IdNotice
    let lengthSendNotice = this.allData.sendNoticeBeans.length
    for (let i = 0; i < lengthSendNotice; i++) {
      if (data.IdNotice == this.allData.sendNoticeBeans[i].idNotice) {
        this.idUserEdit = this.allData.sendNoticeBeans[i].idNotice;
      }

    }
    this.AsuntoEdit = data.Asunto
    this.selectStatusEdit = [];
    this.selectStatusEdit.push({ label: this.varActive, value: "1" })
    this.selectStatusEdit.push({ label: this.varInactive, value: "2" })
    this.statusEdit = data.Estatus
    $('#updateModal').modal('show');
    $("body").removeAttr("style");
  }

  deleteModal(data: any) {
    let lengthSendNotice = this.allData.sendNoticeBeans.length
    for (let i = 0; i < lengthSendNotice; i++) {
      if (data.IdNotice == this.allData.sendNoticeBeans[i].idNotice) {
        this.idUserEdit = this.allData.sendNoticeBeans[i].idNotice;
        this.AsuntoEdit = this.allData.sendNoticeBeans[i].subject
      }

    }
    $('#deleteModal').modal('show');
    $("body").removeAttr("style");
  }

  closeModalAdd() {
    this.getData(this.idUser)
    $('#addModal').modal('hide');
    $("body").change(function () {
      $(this).val('style');
    });
  }

  closeEditModal(){
    $('#updateModal').modal('hide');
  }

  AddModal() {
    $('#addModal').modal('show');
    $("body").removeAttr("style");

  }

  clearEdit() {
    this.idCodServEdit = null
    this.nameCodServEdit = null
    this.descriptionEdit = null
    this.idSelectedclassAdd = null
    this.idSelectedclassificationAdd = null
    this.idSelectedAirlineEdit = null
    this.classificationEdit = null
    this.calendarInitEdit = null
    this.AsuntoEdit = null
    this.idStatus = null
    this.idUserEdit = null

  }

  updateService(type: number) {
    this.blockUI.start(this.varLoading);
    let statusNotice = this.idStatus
    if (type == 2) {
      statusNotice = 3
    }

    this.subscription.add(this.noticeService.updateNotice(statusNotice.toString(), this.idUserEdit.toString(), this.idUser.toString()).subscribe((data: any) => {
      this.blockUI.stop();
      let status = 0;
      status = data.Status
      if (status == 200) {
        this.blockUI.stop();
        if (type == 2) {
          this.toastr.info(this.varNoticeDelete, this.varSuccessful, { positionClass: 'toast-bottom-full-width', closeButton: true });
          this.notificationsFunction();
        } else {
          this.toastr.info(this.varNoticeUpdated, this.varSuccessful, { positionClass: 'toast-bottom-full-width', closeButton: true });
        }

        this.getData(this.idUser)
        this.clearEdit()
      }
    }, error => {
      this.blockUI.stop();
      if (error.status === 401 || error.status === 0) {
        this.blockUI.stop();
        this.toastr.error(this.varSessionExpired, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
        localStorage.setItem('logued', 'false');
        this.router.navigate(['/login']);
      } else if (error.status === 404) {
        this.blockUI.stop();
        this.toastr.error(this.varTryAgain, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
      } else if (error.status === 500 || error.status === 400) {
        this.blockUI.stop();
        this.toastr.error(this.varConnectionError, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
      }
    }));
    $('#updateModal').modal('hide');
    $("body").change(function () {
      $(this).val('style');
    });
  }

  updateStatus(idStatus: any) {
    this.idStatus = idStatus
  }

  archivo(c) {
    let fileP = "../../assets/file/AttachFileNotice2019-10/AttachFilemlorfk.pdf";
    var element = document.createElement('a');
    element.setAttribute('href', c.fileBase64.toString());
    element.setAttribute('download', c.name.toString());
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);

  }

  viewpdf(data: any) {
    this.blockUI.start();
    this.routePDF = '';
    this.searchId(data)
    this.subscription.add(this.noticeService.getViewNotice(data.IdNotice, this.idUser, localStorage.getItem('language')).subscribe((data: any) => {

      let status = 0;
      status = data.ClassInfo.Status
      if (status == 200) {

        if (data.file.fileBase64 != null) {
          $('#addModalFile').modal('show');

          const byteArray = new Uint8Array(atob(data.file.fileBase64.toString()).split('').map(char => char.charCodeAt(0)));
          var fileURL = URL.createObjectURL(new Blob([byteArray], {type: 'application/pdf'}));

          const uniqueId = Math.floor(Math.random() * 10000) + 1;


          let pdfContent: any = fileURL + '#' + uniqueId + '&toolbar=0&navpanes=0&scrollbar=0&view=FitH';
          this.pdfview.nativeElement.setAttribute('data', pdfContent);
        }
        else
        {
          this.toastr.error(this.varFileNotFound, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
        }
      }
      this.blockUI.stop();
    }, error => {
      if (error.status === 401 || error.status === 0) {

        this.blockUI.stop();
        this.toastr.error(this.varSessionExpired, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
        localStorage.setItem('logued', 'false');
        this.router.navigate(['/login']);
      } else if (error.status === 404) {
        this.blockUI.stop();
        this.toastr.error(this.varTryAgain, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
      } else if (error.status === 500 || error.status === 400) {
        this.blockUI.stop();
        this.toastr.error(this.varConnectionError, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
      }
    }));
    this.notificationsFunction();
  }

  download(c) {
    let path = c.base64;

    this.subscription.add(this.filesService.getFile(path).
      subscribe(
        (data: any) => {
          if (data.ClassInfo.Status === 200) {
            /*const downloadLink = document.createElement(`a`);
            downloadLink.href = `${c.header}${data.fileBase64.base64}`;
            downloadLink.download = `Attached-${c.name}.${c.type}`;
            downloadLink.click();*/
            const byteArray = new Uint8Array(atob(data.fileBase64.base64).split('').map(char => char.charCodeAt(0)));
            this.blob = new Blob([byteArray], { type: 'application/pdf' });
            this.blob.saveFile(`Attached-${c.name}.${c.type}`);
          }
          this.blockUI.stop();
        },
        error => {
          this.blockUI.stop();
          if (error.status === 401 || error.status === 0) {
            this.blockUI.stop();
            this.toastr.error(this.languageGlobal.sessionExpired, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
            localStorage.setItem('logued', 'false');
            this.router.navigate(['/login']);
          } else if (error.status === 404) {
            this.blockUI.stop();
            this.toastr.error(this.languageGlobal.tryAgain, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
          } else if (error.status === 500 || error.status === 400) {
            this.blockUI.stop();
            this.toastr.error(this.languageGlobal.connectionError, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
          }
        }
      ));
  }
  notificationsFunction() {
    this.getData(this.idUser)
    this.blockUI.start(this.varLoading);
    this.subscription.add(this.noticeService.getUnreadNotice(this.idUser).subscribe((data: any) => {
      let status = 0;
      status = data.ClassInfo.Status
      if (status == 200) {
        this.blockUI.stop();
        this.badgeCount = data.unreadNumber;
        this.NavbarLoguedComponent.notifications();
      }

    }, error => {
      this.blockUI.stop();
      if (error.status === 401 || error.status === 0) {
        this.blockUI.stop();
        this.toastr.error(this.varSessionExpired, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
        localStorage.setItem('logued', 'false');
        this.router.navigate(['/login']);
      } else if (error.status === 404) {
        this.blockUI.stop();
        this.toastr.error(this.varTryAgain, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
      } else if (error.status === 500 || error.status === 400) {
        this.blockUI.stop();
        this.toastr.error(this.varConnectionError, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  validateExtensionExcel(type) {
    return this.extensionsExcel.find(t => {
      if (type === t) {
        return true
      }
      return false;
    })

  }
  validateExtensionWord(type) {
    return this.extensionsWord.find(t => {
      if (type === t) {
        return true
      }
      return false;
    })
  }
  validateExtensionPowerPoint(type) {
    return this.extensionsPowerPoint.find(t => {
      if (type === t) {
        return true
      }
      return false;
    })
  }
  validateExtensionImage(type) {
    return this.extensionsImage.find(t => {
      if (type === t) {
        return true
      }
      return false;
    })
  }
  validateExtensionVideo(type) {
    return this.extensionsVideo.find(t => {
      if (type === t) {
        return true
      }
      return false;
    })
  }
  validateExtensionPdf(type) {
    return this.extensionsPdf.find(t => {
      if (type === t) {
        return true
      }
      return false;
    })
  }

  validateIcon(type: string) {
    let typeL = type.toLowerCase();
    if (this.validateExtensionExcel(typeL)) {
      return 'fas fa-file-excel';
    } else if (this.validateExtensionImage(typeL)) {
      return 'fas fa-image';
    } else if (this.validateExtensionPdf(typeL)) {
      return 'fas fa-file-pdf'
    } else if (this.validateExtensionPowerPoint(typeL)) {
      return 'fas fa-file-powerpoint'
    } else if (this.validateExtensionVideo(typeL)) {
      return 'fas fa-video';
    } else if (this.validateExtensionWord(typeL)) {
      return 'fas fa-file-word';
    }
  }
}
