<block-ui>
    <div style="max-width:400px; width:100%; margin:0 auto;">
        <div class="card special-card">
            <div class="card-body">
              <form (ngSubmit)="validateUser(data)" #data="ngForm" novalidate="">
                    <h2>{{languageModule.login}}</h2>
                    <div class="input-group top-buffer">
                        <div class="input-group-prepend">
                            <span class="input-group-text" style="height: 38px;border-radius: 0.100rem;"><i class="fas fa-user"></i></span>
                        </div>

                       <input type="mail"  class="form-control" maxlength="50" (keyup)="NumText($event)" name="txtUserName" #txtUserName="ngModel" [(ngModel)]="credentials.Username"
                         autocomplete="off" [placeholder]="languageModule.email" title="Debe ingresar un correo valido" pattern="^[a-zA-Z0-9._-]+@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{1,5}$"   required >
                    </div>
                    <div class="input-group top-buffer">
                        <div class="input-group-prepend">
                            <span class="input-group-text" style="height: 38px;border-radius: 0.100rem;"><i class="fas fa-key"></i></span>
                        </div>
                        <input type="{{(showPsw === false)?'password':'text'}}" class="form-control" name="txtPasword" #txtPassword="ngModel" [(ngModel)]="credentials.Password"
                        [placeholder]="languageModule.clave" autocomplete="off"   title="Debe ingresar su password" required>
                        <mat-icon matSuffix style="position: absolute; right: 5px; top: 11px; z-index: 100;" (click)="toggleShowPsw()">
                          {{!showPsw?'visibility_off':'visibility'}}
                        </mat-icon>
                    </div>
                    <div style="text-align: center; margin-top: 5px;;"> <a [routerLink]="['/forgotPassword']" style="color: white; text-decoration: none; "> {{languageModule.recoverClave}}</a> </div>

                    <div class="top-buffer" align="right">
                        <button type="submit" [disabled]="!data.form.valid" class="btn btn-primary"><i class="fas fa-sign-in-alt"></i> {{languageModule.in}}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</block-ui>
