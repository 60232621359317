<block-ui>
  <div class="container-fluid">
    <div class="row divTitle">
      <section [defaultImage]="defaultImage" [lazyLoad]="image" class="titleCat">
        <h1 class="titleComponent">{{varTitletConsultNotice}}</h1>
      </section>
      <!--  <section style="background:url('../../assets/images/consultNotice.jpg');" class="titleCat" >
                <h1 class="titleComponent">{{varTitletConsultNotice}}</h1>
            </section> -->
    </div>
    <div class="containerSendNotice containerConsultNotice">
      <p-table [columns]="cols" [scrollable]="true" [value]="consultNotice" #dtt dataKey="consultNotice"
        [paginator]="true" [rows]="5" [rowsPerPageOptions]="[5,10,20]" [resizableColumns]="true"
        [reorderableColumns]="true">
        <ng-template pTemplate="caption">
          <div style="text-align: left;">
            <input class="containerFiltro " type="text" pInputText size="50" placeholder={{varGlobalFilter}}
              (input)="dtt.filterGlobal($event.target.value, 'contains')" style="width:auto">
          </div>
          <div class="buttonAddGral" *ngIf="canCreate">
            <button type="button" class="btn-options styleButtonTable" [routerLink]="['/sendNotice']"><i
                class="fas fa-plus-circle"></i>&nbsp; {{varAdd}}</button>
          </div>
          <br>
        </ng-template>
        <ng-template pTemplate="header" let-columns style="width: 80%">
          <tr>
            <th class="headertable " *ngFor="let col of columns" [pSortableColumn]="col.field" pResizableColumn
              pReorderableColumn>
              {{col.header}}
              <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
            <th class="headertable" *ngIf="canRead"> {{varSeeNotice}}</th>
            <th class="headertable" *ngIf="canUpdate"> {{varEdit}}</th>
            <th class="headertable" *ngIf="canDelete">{{varDelete}}</th>
            <th class="headertable" *ngIf="canRead">{{varReadingStatus}}</th>
          </tr>
          <tr style="width: 50%">
            <th *ngFor="let col of columns" [ngSwitch]="col.field" class="ui-fluid styleTable">
              <input pInputText type="text" (input)="dtt.filter($event.target.value, col.field, 'contains')"
                [value]="$any(dtt.filters[col.field])?.value">
            </th>
            <th class="styleTable" *ngIf="canRead"></th>
            <th class="styleTable" *ngIf="canUpdate"></th>
            <th class="styleTable" *ngIf="canDelete"></th>
            <th class="styleTable" *ngIf="canRead"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-c let-columns="columns">
          <tr class="uppercase center" [ngClass]="{'readNotice': c.readNotice === 2}">



            <td class="styleTable" style="padding: 0.571em 7.5em;">{{c.IdNotice}}</td>
            <td class="styleTable" style="padding: 0.571em 3.0em;">{{c.Referencia}}</td>
            <td class="styleTable" style="padding: 0.571em 3.8em;"> {{c.FechaCreacionFormat}}</td>
            <td class="styleTable" style="padding: 0.571em 3.8em;">{{c.Categoria}}</td>
            <td class="styleTable" style="padding: 0.571em 3.8em;">{{c.Subcategoria}}</td>
            <td class="styleTable" style="padding: 0.571em 3.8em;">{{c.Asunto}}</td>
            <td class="styleTable" style="padding: 0.571em 3.8em;">{{c.InicioVigenciaFormat}}</td>
            <td class="styleTable" style="padding: 0.571em 3.8em;">{{c.FinVigenciaFormat}}</td>
            <td class="styleTable" style="padding: 0.571em 3.8em;">{{c.Estatus}}</td>
            <td class="styleTable" style="padding: 0.571em 5.5em;">{{c.ModificadoPor}}</td>
            <td class="styleTable" style="padding: 0.571em 8.5em;">{{c.FechaModifcado}}</td>
            <!--   <td class="styleTable" *ngIf="c.Adjunto === null">{{c.Adjunto}}</td>
                        <td class="styleTable" *ngIf="c.Adjunto !== null">
                            <button type='button' class='btn-options styleButtonTable' (click)='attach(c)'><i
                                    class='fas fa-paperclip'></i>&nbsp; {{varFile}}</button>
                        </td> -->
            <td class="styleTable" style="width: max-content; padding: 0.571em 5.5em;">
              <div style="display: flex; border: transparent;">
                <div *ngFor="let attached of c?.attachedFile">
                  <a style="margin-right: 5px;" href="javascript:" type="button"
                    class="btn-options styleButtonTable pr-1 mb-1" (click)="download(attached)">
                    <i [ngClass]="validateIcon(attached.type)"></i>
                  </a>
                  <!--<button style="margin-right: 5px;" type="button" class="btn-options styleButtonTable pr-1 mb-1"
                    (click)="download(attached)"><i [ngClass]="validateIcon(attached.type)"></i></button>-->
                </div>
              </div>
            </td>
            <td *ngIf="canRead" class="styleTable" style="padding: 0.571em 3.8em;"><button type="button"
                class="btn-options styleButtonTable" (click)="viewpdf(c)"><i class="fa fa-eye iconEye"></i></button>
            </td>
            <td *ngIf="canUpdate" class="styleTable" style="padding: 0.571em 3.8em;"><button type="button"
                class="btn-options styleButtonTable " (click)="UpdateModal(c)"><i
                  class="fa fa-edit iconEdit"></i></button></td>
            <td *ngIf="canDelete" class="styleTable" style="padding: 0.571em 2.8em;"><button type="button"
                class="btn-options styleButtonTable " (click)="deleteModal(c)"><i
                  class="fa fa-trash-alt iconDelete"></i> </button> </td>
            <td *ngIf="canRead" class="styleTable" style="padding: 0.571em 2.8em;"><button type="button"
                class="btn-options styleButtonTable " (click)="getTableDisplay(c)"><i class="fa fa-book iconBook"
                  aria-hidden="true"></i>&nbsp;</button></td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</block-ui>
<!-- /******************************************UPDATE MODAL*****************************************************/ -->
<div class="modal fade bd-example-modal-l" id="updateModal" tabindex="-1" role="dialog"
  aria-labelledby="myLargeModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="modal-dialog modal-lg">
    <div class="modal-content containerModalEdit">
      <div class="modal-header">
        <div class="row">
          <div class="col-11">
            <h5 class="modal-title" id="exampleModalLabel">{{varEditNotice}} {{AsuntoEdit}}</h5>
          </div>
        </div>
        <div class="col-1">
          <button type="button" class="close" data-dismiss="modal" (click)="closeEditModal()" aria-label="Close">
            <i class="fa fa-times"></i>
          </button>
        </div>


      </div>
      <div class="modal-body" style="margin-top: -40px;">
        <br>
        <br>
        <label>{{varStatus}}</label><br>
        <select class="form-select fontSizeLabelModal" (change)="updateStatus($event.target.value)" name="statusSEdit"
          id="statusSEdit" [(ngModel)]="statusEdit" required>
          <option [value]="status.value" *ngFor="let status of selectStatusEdit">{{status.label}}</option>
        </select>
        <br>
        <br>
        <button type="button" class="btn-options styleButtonTable saveEditConsultNotice" (click)="updateService(1)"><i
            class="fa fa-save"></i>&nbsp; {{varSave}}</button>
      </div>
    </div>
  </div>
</div>
<!-- /******************************************Delete MODAL*****************************************************/ -->
<div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true" data-bs-backdrop="static">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{varQuestionDeleteNotice}} {{nameAdjuntoEdit}}
        </h5>
        <button type="button" class="close" (click)="clearEdit()" data-bs-dismiss="modal" aria-label="Close">
          <i class="fa fa-times"></i>
        </button>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn-options styleButtonTable" data-bs-dismiss="modal"
          (click)="updateService(2)">{{varYes}}</button>
        <button type="button" (click)="clearEdit()" class="btn-options styleButtonTable"
          data-bs-dismiss="modal">No</button>
      </div>
    </div>
  </div>
</div>
<!-- /******************************************Modal Estatus de Lectura*****************************************************/ -->
<div class="modal fade bd-example-modal-l" id="getTableDisplay" tabindex="-1" role="dialog"
  aria-labelledby="myLargeModalLabel" aria-hidden="true" data-bs-backdrop="static">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{varReadingStatus}}</h5>
        <button type="button" class="close" data-bs-dismiss="modal" (click)=clearEdit() aria-label="Close">
          <i class="fa fa-times"></i>
        </button>
      </div>
      <div class="modal-body">
        <br>
        <br>
        <div class="content containerDisplayStatus">
          <p-table [columns]="colsDisplay" [value]="consultNoticeDisplay" [paginator]="true" [rows]="5" #dtnc
            dataKey="consultNoticeDisplay" [resizableColumns]="true" [reorderableColumns]="true"
            [rowsPerPageOptions]="[5,10,20]">
            <ng-template pTemplate="caption">
              <div style="text-align: left;">
                <input class="containerFiltroNotice " type="text" pInputText size="50" placeholder={{varGlobalFilter}}
                  (input)="dtnc.filterGlobal($event.target.value, 'contains')" style="width:auto">
              </div>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th class="headertable" *ngFor="let col of columns" [pSortableColumn]="col.field" pResizableColumn
                  pReorderableColumn>
                  {{col.header}}
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
              </tr>
              <tr>
                <th *ngFor="let col of columns" [ngSwitch]="col.field" class="ui-fluid styleTable">
                  <input pInputText type="text"
                    (input)="dtnc.filter($event.target.value, col.field, col.filterMatchMode)"
                    [value]="$any(dtnc.filters[col.field])?.value">
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-c let-columns="columns">
              <tr>
                <td *ngFor="let col of columns" class="styleTable uppercase center">
                  {{c[col.field]}}
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
        <br>
        <br>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="addModalFile" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true" data-bs-backdrop="false">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{varFile}}</h5>
        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
          <i class="fa fa-times"></i>
        </button>
      </div>
      <div class="modal-body">
        <object #pdfview [data]='' type="application/pdf" width="100%" height="700px"></object>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn-options styleButtonTable close" data-bs-dismiss="modal">{{varClose}}</button>
      </div>
    </div>
  </div>
</div>
