<block-ui>
  <div class="container-fluid" style="width: 100%!important;">
    <div class="row divTitle">
      <section [defaultImage]="defaultImage" [lazyLoad]="image2" class="titleCat">
        <h1 class="titleComponent">{{varMaster}}</h1>
      </section>
    </div>
    <div class="containerMasterPrincipal">
      <div style="text-align: left;">
        <input type="text" class="containerFiltro uppercaseSearch" pInputText size="50" placeholder={{varGlobalFilter}}
          (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
      </div>
      <div class="buttonAddGral">
        <button *ngIf="canCreate" style="margin-right: 30px;" type="button" class="btn-options styleButtonTable"
          [routerLink]="['/formularioMaster']"><i class="fas fa-plus-circle"></i>&nbsp; {{varAdd}}</button>
        <button *ngIf="canRead" type="button" class="btn-options styleButtonTable" (click)="downloadExcel()"><i
            class="fa fa-download"></i>&nbsp; {{varExport}}</button>
      </div>
      <br>
      <p-table [columns]="cols" [value]="availableMaterials" #dt dataKey="idMaster" [expandedRowKeys]="expandedRows"
        [resizableColumns]="true" [reorderableColumns]="true" [paginator]="true" [rows]="5">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th style="width: 3em" class="headertable"></th>
            <th *ngFor="let col of cols" class="headertable" pResizableColumn pReorderableColumn
              [pSortableColumn]="col.field">
              {{col.header}}
              <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
            <th class="headertable">{{varPicture}}</th>
            <th class="headertable" *ngIf="canUpdate"> {{varEdit}}</th>
            <th class="headertable" *ngIf="canDelete">{{varDelete}}</th>
          </tr>
          <tr>
            <th></th>
            <th class="styleTable" *ngFor="let col of columns" class="ui-fluid styleTable">
              <input class="uppercaseSearch" pInputText type="text"
              (input)="dt.filter($event.target.value, col.field, col.filterMatchMode)"
                >
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr [pSelectableRow]="rowData" class="uppercase" style="text-align: center;">
            <td (click)="getDataKit(rowData)">
              <a href="#" [pRowToggler]="rowData">
                <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></i>
              </a>
            </td>
            <td>{{rowData.lote}}</td>
            <td>{{rowData.UM}}</td>
            <td>{{rowData.description}}</td>
            <td>{{rowData.descriptionEnglish}}</td>
            <td>{{rowData.container}}</td>
            <td>{{rowData.localStations}}</td>
            <td>{{rowData.internationalStaions}}</td>
            <td>{{rowData.cycle}}</td>
            <td>{{rowData.commentary}}</td>
            <td>{{rowData.dateModified| date:'dd/MM/yyyy'}}</td>
            <!--<td style="width: 10%!important">
              <img [src]="rowData.picture | safe : 'url'" style=" width: 100%; text-align: center;">
            </td>-->
            <td>
              <button *ngIf="rowData.picture !== ''" type="button" class="btn-options styleButtonTable" (click)="viewImage(rowData)">
                <i class="fas fa-image"></i>
              </button>
            </td>
            <td *ngIf="canUpdate" class="styleTable">
              <button type="button" class="btn-options styleButtonTable" (click)="EditModal(rowData)">
                <i class="fas fa-edit"></i>
              </button>
            </td>
            <td *ngIf="canDelete" class="styleTable">
              <button type="button" class="btn-options styleButtonTable" (click)="deleteModal(rowData)">
                <i class="fas fa-trash-alt"></i>
              </button>
            </td>

          </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-rowData let-columns="columns">
          <tr *ngFor="let dataKit of arrayKit">
            <td [attr.colspan]="columns.length + 3">
              <div class="ui-g ui-fluid" style="font-size:16px;padding:20px; display: flex;"
                [@rowExpansionTrigger]="'active'">
                <div class="ui-g-12 ui-md-3 expDivContainer">
                  <img class="expansionImg" [attr.alt]="dataKit.image"
                    [src]="dataKit.image | safe : 'url'">
                </div>
                <div class="ui-g-12 ui-md-9 uppercase" style="padding-left: 12%!important;">
                  <div class="ui-g">
                    <div class="ui-g-12">
                      <b>ID:</b> {{dataKit.idCatMaterials}}
                    </div>
                    <div class="ui-g-12">
                      <b>{{varPartNumber}}:</b> {{dataKit.partNumber}}
                    </div>
                    <div class="ui-g-12">
                      <b>{{varShortDescription}}:</b> {{dataKit.shortDescription}}
                    </div>
                    <div class="ui-g-12">
                      <b>{{varDescriptionEnglish}}:</b> {{dataKit.descriptionEnglish}}
                    </div>
                    <div class="ui-g-12">
                      <b>{{varQuantity}}:</b> {{dataKit.quantity}}
                    </div>
                    <div class="ui-g-12">
                      <b>{{varComentary}}:</b> {{dataKit.comentary}}
                    </div>
                    <div class="ui-g-12">
                      <b>{{varReusable}}:</b> {{TextReusable(dataKit.reuilizable)}}
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </ng-template>

      </p-table>
    </div>
  </div>
</block-ui>

<!-- /******************************************EDIT MODAL*****************************************************/ -->
<div class="modal fade bd-example-modal-l" id="EditModal" tabindex="-1" role="dialog"
  aria-labelledby="myLargeModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="modal-dialog modal-lg marginModal ">
    <div class="modal-content modalSendNotice">
      <div class="modal-body">
        <button type="button" class="close" aria-label="Close" (click)="clearEdit()" style="margin-left: 98%;"
          data-dismiss="modal">
          <i class="fa fa-times"></i>
        </button>
        <div class="modal-body" style="margin-top: 23px; margin-left: 0px;">
          <div class="container-fluid" style="width: 100%!important;">
            <div class="row">
              <section style="background:url('../../assets/images/sendNotice.jpg'); " class="titleCat">
                <h1 class="titleComponent">{{varKitEdit}}</h1>
              </section>
            </div>
            <div *ngIf="tablaKit === false" class="content containerSendNotice"
              style=" margin-top: 60px; margin-left: 20%;">

              <br>
              <div class="row">
                <div class="col-sm-4 col-md-4 col-6 marginColumns">
                  <label class="obligation">**</label> <label class="fontSizeLabelModal"><b>&nbsp;
                      {{varLot}}:
                    </b>
                  </label>
                </div>
                <div class="col-sm-6 col-md-6 col-6">
                  <input type="text" pInputText [(ngModel)]="lotEdit" style="width: 50%;">
                </div>
                <div class="col-sm-4 col-md-4 col-4"></div>
              </div>

              <br>
              <div class="row">
                <div class="col-sm-4 col-md-4 col-6 marginColumns">
                  <label class="obligation">**</label> <label class="fontSizeLabelModal"><b>&nbsp;
                      {{varDescription}}:
                    </b>
                  </label>
                </div>
                <div class="col-sm-6 col-md-6 col-6">
                  <input type="text" pInputText [(ngModel)]="descriptionEdit" style="width: 50%;">
                </div>
                <div class="col-sm-4 col-md-4 col-4"></div>
              </div>
              <br>
              <div class="row">
                <div class="col-sm-4 col-md-4 col-6 marginColumns">
                  <label class="obligation">**</label> <label class="fontSizeLabelModal"><b>&nbsp;
                      {{varDescriptionEnglish}}:
                    </b>
                  </label>
                </div>
                <div class="col-sm-6 col-md-6 col-6">
                  <input type="text" pInputText [(ngModel)]="descriptionEnglishEdit" style="width: 50%;">
                </div>
                <div class="col-sm-4 col-md-4 col-4"></div>
              </div>
              <br>

              <div class="row">
                <div class="col-sm-4 col-md-4 col-6 marginColumns">
                  <label class="obligation">**</label> <label class="fontSizeLabelModal"><b>&nbsp;
                      {{varContainer}}:
                    </b>
                  </label>
                </div>
                <div class="col-sm-6 col-md-6 col-6">
                  <input type="text" pInputText [(ngModel)]="containerEdit" style="width: 50%;">
                </div>
                <div class="col-sm-4 col-md-4 col-4"></div>
              </div>
              <br>

              <div class="row">
                <div class="col-sm-4 col-md-4 col-6 marginColumns">
                  <label class="obligation">**</label> <label class="fontSizeLabelModal"><b>&nbsp; UM
                    </b> </label>
                </div>
                <div class="col-sm-6 col-md-6 col-6">
                  <select class="form-select fontSizeLabelModal" style="width: 50%;" name="valueUM" id="valueUM"
                    [(ngModel)]="idSelectedUmEdit" required>
                    <option [value]="umValue.label" *ngFor="let umValue of umSelectEdit">
                      {{umValue.label}}
                    </option>
                  </select>
                </div>
                <div class="col-sm-4 col-md-4 col-4"></div>
              </div>

              <br>

              <div class="row">
                <div class="col-sm-4 col-md-4 col-6 marginColumns">
                  <label class="obligation">**</label> <label class="fontSizeLabelModal"><b>&nbsp;
                      {{varLocalStation}}:
                    </b> </label>
                </div>
                <div class="col-sm-4 col-md-4 col-6 marginColumns">
                  <select class="form-select fontSizeLabelModal" style="width: 77%;" name="localStation"
                    id="localStation" [(ngModel)]="idSelectedLocalStationEdit" required>
                    <option [value]="localSupplyng.label" *ngFor="let localSupplyng of localStationSelectEdit">
                      {{localSupplyng.label}}
                    </option>
                  </select>
                </div>
                <div class="col-sm-4 col-md-4 col-4"> </div>
              </div>
              <br>
              <div class="row">
                <div class="col-sm-4 col-md-4 col-6 marginColumns">
                  <label class="obligation">**</label> <label class="fontSizeLabelModal"><b>&nbsp;
                      {{varInternationalStation}}:</b> </label>
                </div>
                <div class="col-sm-6 col-md-6 col-6">
                  <select class="form-select fontSizeLabelModal" style="width: 50%;" name="internationalStation"
                    id="internationalStation" [(ngModel)]="idSelectedInternationalStationEdit" required>
                    <option [value]="supplyng.label" *ngFor="let supplyng of internationalStationSelectEdit">
                      {{supplyng.label}}
                    </option>
                  </select>
                </div>
                <div class="col-sm-4 col-md-4 col-4"></div>
              </div>
              <br>
              <div class="row">
                <div class="col-sm-4 col-md-4 col-6 marginColumns">
                  <label class="obligation">**</label> <label class="fontSizeLabelModal"><b>&nbsp;
                      {{languageModule.cycle}}:</b> </label>
                </div>
                <div class="col-sm-6 col-md-6 col-6">

                  <p-multiSelect [options]="cyclesSelectEdit" class="fontSizeLabelModal uppercase"
                    [(ngModel)]="idSelectedCycleEdit" autoWidth="false" defaultLabel="   "
                    [style]="{'width':'50%'}"></p-multiSelect>
                </div>
                <div class="col-sm-4 col-md-4 col-4"></div>
              </div>
              <br>

              <div class="row">
                <div class="col-sm-4 col-md-4 col-6 marginColumns">
                  <label class="obligation">**</label> <label class="fontSizeLabelModal"><b>&nbsp;
                      {{languageModule.commentary}}: </b> </label>
                </div>
                <div class="col-sm-6 col-md-6 col-6">
                  <span class="ui-float-label mb-4 mt-3">
                    <textarea pInputTextarea style="width: 100%!important;" pInputTextarea maxlength="1000"
                      [(ngModel)]="commentaryEdit" style="width: 50%;"></textarea>
                  </span>
                </div>
                <div class="col-sm-4 col-md-4 col-4"></div>
              </div>
              <br>

              <div class="row">
                <div class="col-sm-4 col-md-4 col-12 marginColumns">
                  <label class="obligation">**</label> <label class="fontSizeLabelModal"><b>&nbsp;
                      {{varPicture}}:</b>
                  </label>
                </div>
                <div class="col-sm-4 col-md-4 col-12">
                  <button type="button" class="styleButtonTable" (click)="abrirEdit('imagenEdit')"><i
                      class='fas fa-paperclip'></i>&nbsp; {{varUploadFile}}</button>
                  <input class="inputSelectFileCycle" type="file" multiple id="imagenEdit" name="imagenEdit"
                    accept="image/*" (change)="incomingfileEdit($event)" style="display: none">
                  <span id="glosaImagenEdits">&nbsp;&nbsp;{{varNoFileSelectedEdit}}</span>

                </div>
                <div class="col-sm-4 col-md-4 col-4"></div>

                <br>
                <br>
              </div>
              <div class="row">
                <div class="col-sm-4 col-md-4 col-12 marginColumns">
                  <label class="obligation">**</label> <label class="fontSizeLabelModal"><b>&nbsp;
                      {{varEffectiveDate}}:</b>
                  </label>
                </div>
                <div class="col-sm-4 col-md-4 col-12">
                  <span class="ui-float-label mb-4 mt-3">
                    <p-calendar [(ngModel)]="effectiveDate" class="col-sm-6 col-md-6 col-6" name="effectiveDate"
                      id="effectiveDate" dateFormat="dd/mm/yy" class="fontSizeLabelModal" [showIcon]="true"
                      class="fontSizeLabelModal" [style]="{'width':'50%'}">
                    </p-calendar>
                  </span>

                </div>
              </div>

              <br>
              <div class="top-buffer" style="text-align: right;">
                <button type="submit" (click)=changeMaster() class="btn-options styleButtonTable"><i
                    class="fa fa-save"></i>&nbsp; {{varSave}}</button>
              </div>
            </div>
            <!-- Segunda seccion de actualizacion -->
            <!-- Listado de Materiales -->
            <div *ngIf="tablaKit === true" class="containerMaster ui-g col-sm-12 col-md-12 col-12">
              <p-splitter [style]="{'height': '100%','border-style': 'none', 'width': '100%'}" [panelSizes]="[30,70]">
                <ng-template pTemplate>
                  <div>
                    <p-table [columns]="cols" [value]="availableMaterialsEdit" dataKey="tableImagen" #dtt
                      [scrollable]="true" scrollHeight="650px">
                      <ng-template pTemplate="caption">
                        <div style="text-align: left;">
                          <input class="containerFiltro " type="text" pInputText size="50"
                            placeholder={{varGlobalFilter}} (input)="filterMaterials($event.target.value)"
                            style="width:auto" (onChange)="ngOnInit()">
                        </div>
                      </ng-template>
                      <ng-template pTemplate="header" let-columns>
                        <tr>
                          <th class="headertable">{{varMaterialsEdit}}</th>
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-tableMaterialEdit let-columns="columns">
                        <tr [pSelectableRow]="contIdEdit" data-b-toggle="modal"
                          (click)="openModal('#AddSuplyEditModal')">
                          <td class="ui-helper-clearfix" pDraggable="materialsEdit"
                            (onDragStart)="dragStart($event,tableMaterialEdit)" (onDragEnd)="dragEnd($event)">
                            <div style="text-align: center; font-weight: bold;">
                              {{tableMaterialEdit.partNumber}} </div>
                            <div class="h-divider">
                              <div class="shadow"></div>
                            </div>

                            <div style="text-align: center">
                              {{tableMaterialEdit.shortDescription}}
                            </div>
                            <div style="text-align: center; margin-bottom: 20px;">
                              {{tableMaterialEdit.familydescription}}
                            </div>
                          </td>
                        </tr>
                      </ng-template>
                    </p-table>
                  </div>
                </ng-template>
                <ng-template pTemplate>
                  <div style="text-align: center;" pDroppable="materialsEdit" (onDrop)="drop($event)"
                    style="margin-left: 0px; " [ngClass]="{'ui-highlight-material':draggedMaterialEdit}">
                    <p-table class="tableMasterEdit" [columns]="cols" [value]="arrayTableEdit" dataKey="partNumber"
                      [(selection)]="selectedCar1Edit">
                      <ng-template pTemplate="header" let-columns>
                        <tr style="text-align: center;">
                          <th style="width: 3em" class="headertable">
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                          </th>
                          <th class="headertable">No. </th>
                          <th class="headertable">{{varQuantity}}</th>
                          <th class="headertable">{{languageModule.reusable}}</th>
                          <th class="headertable">{{languageModule.commentary}}</th>
                          <th class="headertable">{{varPartNumber}}</th>
                          <th class="headertable">{{varShortDescription}}</th>
                          <th class="headertable">{{varDescriptionEnglish}}</th>
                          <!--  <th class="headertable"> {{varSupplyingEdit}}</th> -->
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-contIdEdit let-columns="columns">
                        <tr pDraggable="test" (onDragStart)="dragStartKit($event,contIdEdit)"
                          (onDragEnd)="dragEndKit($event)" [pSelectableRow]="contIdEdit">
                          <td>
                            <p-tableCheckbox [value]="contIdEdit" (click)="idKit(contIdEdit);">
                            </p-tableCheckbox>
                          </td>
                          <td>{{contIdEdit.id}} </td>
                          <td pEditableColumn>
                            <p-cellEditor>
                              <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="contIdEdit.quantity">
                              </ng-template>
                              <ng-template pTemplate="output">
                                {{contIdEdit.quantity}}
                              </ng-template>
                            </p-cellEditor>
                          </td>
                          <td style="text-align: center!important;">
                            <input (click)="check()" type="checkbox" [(ngModel)]="contIdEdit.reusable" />
                          </td>
                          <td pEditableColumn>
                            <p-cellEditor>
                              <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="contIdEdit.commentaryedit">
                              </ng-template>
                              <ng-template pTemplate="output">
                                {{contIdEdit.commentaryedit}}
                              </ng-template>
                            </p-cellEditor>
                          </td>
                          <td>{{contIdEdit.partNumber}}</td>
                          <td>{{contIdEdit.shortDescription}}</td>
                          <td>{{contIdEdit.descriptionEnglish}}</td>
                        </tr>

                      </ng-template>
                      <ng-template pTemplate="summary">
                        <div style="text-align: right;">
                          <button type="button" class="btn-options styleButtonTable" (click)="deleteMaterialEdit ();"><i
                              class="fa fa-trash-alt iconDelete"></i>&nbsp;
                            {{varDelete}}</button>
                        </div>
                        <div style="text-align: right; margin-top: 15px;">
                          <button type="button" class="btn-options styleButtonTable"
                            (click)="sendCompleteKitEdit(arrayTableEdit);"><i class="fa fa-save"></i>&nbsp;
                            {{varSave}}</button>
                        </div>
                      </ng-template>
                    </p-table>
                    <!-- Seccion Detalle -->
                    <div style="margin-top: 20px;" *ngIf="dataMasterEdit">
                      <p-splitter
                        [style]="{'height': '100%','border-style': 'none', 'width': '100%', 'border-left': '10px'}"
                        [panelSizes]="[50,50]" gutterSize="0">
                        <ng-template pTemplate>
                          <div class="col-sm-6 col-md-6 col-6" style="width: 80%;">
                            <label class="fontSizeLabelModal"><b>&nbsp; {{varLot}}:</b>
                              {{dataMasterEdit.lote}} </label>
                            <br>
                            <label class="fontSizeLabelModal"><b>&nbsp;
                                {{varDescription}}:</b>
                              {{dataMasterEdit.description}} </label>
                            <br>
                            <label class="fontSizeLabelModal"><b>&nbsp;
                                {{varDescriptionEnglish}}:</b>
                              {{dataMasterEdit.descriptionEnglish}} </label>
                            <br>
                            <label class="fontSizeLabelModal"><b>&nbsp;
                                {{varContainer}}:</b>
                              {{dataMasterEdit.container}} </label>
                            <br>
                            <label class="fontSizeLabelModal"><b>&nbsp; UM:</b>
                              {{dataMasterEdit.idUM}}
                            </label>
                            <br>

                          </div>
                        </ng-template>
                        <ng-template pTemplate>
                          <div class="col-sm-6 col-md-6 col-6" style="width: 80%;">
                            <label class="fontSizeLabelModal"><b>&nbsp;
                                {{varLocalStation}}:</b>
                              {{dataMasterEdit.idLocalStations}} </label>
                            <br>
                            <label class="fontSizeLabelModal"><b>&nbsp;
                                {{varInternationalStation}}:</b>
                              {{dataMasterEdit.idInternationalStaions}} </label>
                            <br>
                            <label class="fontSizeLabelModal"><b>&nbsp;
                                {{languageModule.cycle}}:</b>
                              {{dataMasterEdit.idCycle}} </label>
                            <br>
                            <label class="fontSizeLabelModal"><b>&nbsp;
                                {{languageModule.commentary}}:</b>
                              {{dataMasterEdit.commentary}} </label>
                            <br>
                            <label *ngIf="valuePicture === true" class="fontSizeLabelModal"><b>&nbsp;
                                {{varPicture}}:</b> {{dataMasterEdit.nameFile}} </label>
                            <br>
                          </div>
                        </ng-template>
                      </p-splitter>
                    </div>
                  </div>
                </ng-template>
              </p-splitter>

              <!-- Seccion de DropDown -->

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- /******************************************Delete MODAL*****************************************************/ -->
<div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel"> {{varQuestionDeleteKit}} {{idLoteDelete}}?
        </h5>
        <button type="button" class="close" data-b-dismiss="modal" aria-label="Close" (click)="closeDeleteModal()">
          <i class="fa fa-times"></i>
        </button>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn-options styleButtonTable" data-bs-dismiss="modal"
          (click)="updateService(2)">{{varYes}}</button>
        <button type="button" class="btn-options styleButtonTable" data-bs-dismiss="modal"
          (click)="closeDeleteModal()">No</button>
      </div>
    </div>
  </div>
</div>


<!-- /******************************************VIEW MODAL*****************************************************/ -->
<div class="modal fade bd-example-modal-l" id="viewImage" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
  aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{varLot}}: {{loteImg}}</h5>
        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
          <i class="fa fa-times"></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="divImgKit">
          <img class="imgKit" [src]="imageKit | safe: 'url'">
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="close styleButtonTable" data-bs-dismiss="modal">
          {{varClose}}
        </button>
      </div>
    </div>
  </div>
</div>
