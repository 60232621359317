import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from '../app/Components/login/login.component';
import { MainComponent } from './Components/main/main.component';
import { GeneralConsultComponent } from './Components/general-consult/general-consult.component';
import { SendNoticeComponent } from './Components/send-notice/send-notice.component';
import { PotensialsComponent } from '../app/Components/potensials/potensials.component';
import { CatRegionsComponent } from './Components/cat-regions/cat-regions.component';
import { CatStationsComponent } from './Components/cat-stations/cat-stations.component';
import { CatUsersComponent } from './Components/cat-users/cat-users.component';
import { CatCyclesComponent } from './Components/cat-cycles/cat-cycles.component';
import { CatCodeServsComponent } from './Components/cat-code-servs/cat-code-servs.component';
import { ConsultPotentialsComponent } from './Components/consult-potentials/consult-potentials.component';
import { CatProvidersComponent } from './Components/cat-providers/cat-providers.component';
import { ConsultNoticeComponent } from './Components/consult-notice/consult-notice.component';
import { GraphicsComponent } from './Components/graphics/graphics.component';
import { ConsultTrolleyMealDistComponent } from './Components/consult-trolley-meal-dist/consult-trolley-meal-dist.component';
import { TrolleyMealDistComponent } from './Components/trolley-meal-dist/trolley-meal-dist.component';
import { ProfilesComponent } from './Components/profiles/profiles.component';
import { AddProfileComponent } from './Components/add-profile/add-profile.component';
import { CatMaterialsComponent } from './Components/cat-materials/cat-materials.component';
import { AuthGuardService } from './Services/auth-guard.service';
import { StatisticsComponent } from './Components/statistics/statistics.component';
import { BoardingTableComponent } from './Components/boarding-table/boarding-table.component';
import { FleetListComponent } from './Components/fleet-list/fleet-list.component';
import { MasterComponent } from './Components/master/master.component';
import { ReportIrregularitiesComponent } from './Components/report-irregularities/report-irregularities.component';
import { CatMasterComponent } from './Components/cat-master/cat-master.component';
import { FormularioMasterComponent } from './Components/formulario-master/formulario-master.component';
import { ListBarComponent } from './Components/list-bar/list-bar.component';
import { CatTsuComponent } from './Components/cat-tsu/cat-tsu.component';
import { CatMatrizComponent } from './Components/cat-matriz/cat-matriz.component';
import { GuideServicesComponent } from './Components/guide-services/guide-services.component';
import { AddComponent } from './Components/cat-tsu/add/add.component';
import { MainTsuComponent } from './Components/cat-tsu/main-tsu/main-tsu.component';
import { EditTsuComponent } from './Components/cat-tsu/edit-tsu/edit-tsu.component';
import { MainMatrizComponent } from './Components/cat-matriz/main-matriz/main-matriz.component';
import { AddMatrizComponent } from './Components/cat-matriz/add-matriz/add-matriz.component';
import { EditMatrizComponent } from './Components/cat-matriz/edit-matriz/edit-matriz.component';
import { DetalleMatrizComponent } from './Components/cat-matriz/detalle-matriz/detalle-matriz.component';
import { CatManualsComponent } from './Components/cat-manuals/cat-manuals.component';
import { CatFoodsComponent } from './Components/cat-foods/cat-foods.component';
import { ForgotPasswordComponent } from './Components/forgot-password/forgot-password.component';
import { FormPasswordComponent } from './Components/form-password/form-password.component';
import { ValidateSessionGuard } from './Services/validate-session.guard';
import { ConsultProductsComponent } from './Components/consult-products/consult-products.component';
import { IrregularityCriteriaComponent } from './Components/irregularity-criteria/irregularity-criteria.component';
import { ConsultEquipmentComponent } from './Components/consult-equipment/consult-equipment.component';
import { RestockCapComponent } from './Components/restock-cap/restock-cap.component';
import { CommonModule } from '@angular/common';
import { UploadFileComponent } from './Components/upload-file/upload-file.component';

import { TableModule } from 'primeng/table';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import { NgxFileDropModule } from 'ngx-file-drop';

const routes: Routes = [

  {
    path: 'login',
    component: LoginComponent,
    /*     canActivate: [AuthGuardService],
        data : { login: true } */
  },
  {
    path: 'forgotPassword',
    loadChildren: () => import('./Components/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
  },
  {
    path: 'formPassword',
    loadChildren: () => import('./Components/form-password/form-password.module').then(m => m.FormPasswordModule)
  },
  {
    path: 'changePassword',
    loadChildren: () => import('./Components/form-password/form-password.module').then(m => m.FormPasswordModule)
  },
  {
    path: 'main',
    loadChildren: () => import('./Components/main/main.module').then(m => m.MainModule),
    canActivate: [AuthGuardService],
    data: { main: true }
  },
  {
    path: 'catCycles',
    loadChildren: () => import('./Components/cat-cycles/cat-cycles.module').then(m => m.CatCyclesModule),
    canActivate: [AuthGuardService],
    data: { idMenu: 1, idSubMenu: 2, idMenuOptions: 1 }
  },
  {
    path: 'catCodeServs',
    loadChildren: () => import('./Components/cat-code-servs/cat-code-servs.module').then(m => m.CatCodeServsModule),
    canActivate: [AuthGuardService],
    data: { idMenu: 1, idSubMenu: 2, idMenuOptions: 2 }
  },
  {
    path: 'consultPotential',
    loadChildren: () => import('./Components/consult-potentials/consult-potentials.module').then(m => m.ConsultPotentialsModule),
    canActivate: [AuthGuardService],
    data: { idMenu: 1, idSubMenu: 2, idMenuOptions: 3 }
  },

  {
    path: 'boardingTable',
    loadChildren: () => import('./Components/boarding-table/boarding-table.module').then(m => m.BoardingTableModule),
    //canActivate: [AuthGuardService],
    data: { idMenu: 1, idSubMenu: 2, idMenuOptions: 4 }
  },
  {
    path: 'generalConsult',
    loadChildren: () => import('./Components/general-consult/general-consult.module').then(m => m.GeneralConsultModule),
    canActivate: [AuthGuardService],
    data: { idMenu: 1, idSubMenu: 29, idMenuOptions: null }
  },
  {
    path: 'catFoods',
    loadChildren: () => import('./Components/cat-foods/cat-foods.module').then(m => m.CatFoodsModule),
    canActivate: [AuthGuardService],
    data: { idMenu: 1, idSubMenu: 32, idMenuOptions: null }
  },
  {
    path: 'digitalMenu',
    loadChildren: () => import('./Components/digital-menu/digital-menu.module').then(m => m.DigitalMenuModule),
    //canActivate: [AuthGuardService],
    data: { idMenu: 1, idSubMenu: 100, idMenuOptions: null }
  },
  {
    path: 'fleetList',
    loadChildren: () => import('./Components/fleet-list/fleet-list.module').then(m => m.FleetListModule),

    canActivate: [AuthGuardService],
    data: { idMenu: 2, idSubMenu: 9, idMenuOptions: null }
  },
  {
    path: 'catMaster',
    loadChildren: () => import('./Components/cat-master/cat-master.module').then(m => m.CatMasterModule),
    canActivate: [AuthGuardService],
    data: { idMenu: 2, idSubMenu: 11, idMenuOptions: null }
  },
  {
    path: 'formularioMaster',
    loadChildren: () => import('./Components/formulario-master/formulario-master.module').then(m => m.FormularioMasterModule),
    canActivate: [AuthGuardService],
    data: { idMenu: 2, idSubMenu: 11, idMenuOptions: null }
  },
  {
    path: 'catMatriz',
    loadChildren: () => import('./Components/cat-matriz/cat-matriz.module').then(m => m.CatMatrizModule),
    canActivate: [AuthGuardService],
    data: { idMenu: 2, idSubMenu: 24, idMenuOptions: null },

  },
  {
    path: 'catTsu',
    loadChildren: () => import('./Components/cat-tsu/cat-tsu.module').then(m => m.CatTsuModule),
    canActivate: [AuthGuardService],
    data: { idMenu: 2, idSubMenu: 12, idMenuOptions: null },
  },
  {
    path: 'graphics',
    loadChildren: () => import('./Components/graphics/graphics.module').then(m => m.GraphicsModule),
    canActivate: [AuthGuardService],
    data: { idMenu: 2, idSubMenu: 13, idMenuOptions: null }
  },
  {
    path: 'ConsultTrolleyMealDist',
    loadChildren: () => import('./Components/consult-trolley-meal-dist/consult-trolley-meal-dist.module')
    .then(m => m.ConsultTrolleyMealDistModule),
    canActivate: [AuthGuardService],
    data: { idMenu: 1, idSubMenu: null, idMenuOptions: null }
  },
  {
    path: 'listBar',
    loadChildren: () => import('./Components/list-bar/list-bar.module').then(m => m.ListBarModule),
    canActivate: [AuthGuardService],
    data: { idMenu: 2, idSubMenu: 15, idMenuOptions: null }
  },
  {
    path: 'guideService',
    loadChildren: () => import('./Components/guide-services/guide-services.module').then(m => m.GuideServicesModule),
    canActivate: [AuthGuardService],
    data: { idMenu: 2, idSubMenu: 17, idMenuOptions: null }
  },
  {
    path: 'guideServiceSisea',
    loadChildren: () => import('./Components/guide-service-sisea/guide-service-sisea.module').then(m => m.GuideServiceSiseaModule),
    canActivate: [AuthGuardService],
    data: { idMenu: 2, idSubMenu: 17, idMenuOptions: null }
  },
  {
    path: 'catMaterials',
    loadChildren: () => import('./Components/cat-materials/cat-materials.module').then(m => m.CatMaterialsModule),
    canActivate: [AuthGuardService],
    data: { idMenu: 2, idSubMenu: 30, idMenuOptions: null }
  },
  {
    path: 'catManuals',
    loadChildren: () => import('./Components/cat-manuals/cat-manuals.module').then(m => m.CatManualsModule),
    canActivate: [AuthGuardService],
    data: { idMenu: 2, idSubMenu: 31, idMenuOptions: null }
  },
  {
    path: 'consultProducts',
    loadChildren: () => import('./Components/consult-products/consult-products.module').then(m => m.ConsultProductsModule),
    canActivate: [AuthGuardService],
    data: { idMenu: 2, idSubMenu: 33, idMenuOptions: null }
  },
  {
    path: 'consultEquipment',
    loadChildren: () => import('./Components/consult-equipment/consult-equipment.module').then(m => m.ConsultEquipmentModule),
    canActivate: [AuthGuardService],
    data: { idMenu: 2, idSubMenu: 33, idMenuOptions: null }
  },
  {
    path: 'restockCap',
    loadChildren: () => import('./Components/restock-cap/restock-cap.module').then(m => m.RestockModule),
    canActivate: [AuthGuardService],
    data: { idMenu: 2, idSubMenu: 35, idMenuOptions: null }
  },
  {
    path: 'statistics',
    loadChildren: () => import('./Components/statistics/statistics.module').then(m => m.StatisticsModule),
    canActivate: [AuthGuardService],
    data: { idMenu: 3, idSubMenu: 18, idMenuOptions: null }
  },
  {
    path: 'reportIrregularities',
    loadChildren: () => import('./Components/report-irregularities/report-irregularities.module').then(m => m.ReportIrregularitiesModule),
    canActivate: [AuthGuardService],
    data: { idMenu: 3, idSubMenu: 19, idMenuOptions: null }
  },
  {
    path: 'sendNotice',
    loadChildren: () => import('./Components/send-notice/send-notice.module').then(m => m.SendNoticeModule),
    canActivate: [AuthGuardService],
    data: { idMenu: 4, idSubMenu: 20, idMenuOptions: null }
  },
  {
    path: 'consultNotice',
    loadChildren: () => import('./Components/consult-notice/consult-notice.module').then(m => m.ConsultNoticeModule),
    canActivate: [AuthGuardService],
    data: { idMenu: 4, idSubMenu: 25, idMenuOptions: null }
  },
  {
    path: 'catProviders',
    loadChildren: () => import('./Components/cat-providers/cat-providers.module').then(m => m.CatProvidersModule),
    canActivate: [AuthGuardService],
    data: { idMenu: 5, idSubMenu: 23, idMenuOptions: null }
  },
  {
    path: 'catRegions',
    loadChildren: () => import('./Components/cat-regions/cat-regions.module').then(m => m.CatRegionsModule),
    canActivate: [AuthGuardService],
    data: { idMenu: 6, idSubMenu: 26, idMenuOptions: 11 }
  },
  {
    path: 'catStation',
    loadChildren: () => import('./Components/cat-stations/cat-stations.module').then(m => m.CatStationsModule),
    canActivate: [AuthGuardService],
    data: { idMenu: 6, idSubMenu: 26, idMenuOptions: 12 }
  }, {
    path: 'irregularityCriteria',
    loadChildren: () => import('./Components/irregularity-criteria/irregularity-criteria.module')
    .then(m => m.IrregularityCriteriaComponentModule),
    canActivate: [AuthGuardService],
    data: { idMenu: 6, idSubMenu: 26, idMenuOptions: 12 }
  }, {
    path: 'catUsers',
    loadChildren: () => import('./Components/cat-users/cat-users.module').then(m => m.CatUsersModule),
    canActivate: [AuthGuardService],
    data: { idMenu: 6, idSubMenu: 27, idMenuOptions: null }
  },
  {
    path: 'profile',
    loadChildren: () => import('./Components/profiles/profiles.module').then(m => m.ProfilesModule),
    canActivate: [AuthGuardService],
    data: { idMenu: 1, idSubMenu: 2, idMenuOptions: 4 }
  },
  {
    path: 'uploadfile',
    loadChildren: () => import('./Components/upload-file/upload-file.module').then(m => m.UploadFileModule),
    canActivate: [AuthGuardService],
    data: { idMenu: 1, idSubMenu: 2, idMenuOptions: 4 }
  },
  {
    path: 'catpreselectemails',
    loadChildren: () => import('./Components/cat-preselect-emails/cat-preselect-emails.module').then(m => m.CatPreselectEmailsModule),
    canActivate: [AuthGuardService],
    data: { idMenu: 1, idSubMenu: 2, idMenuOptions: 4 }
  },

  { path: '**', pathMatch: 'full', redirectTo: 'login' },
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  { path: ' ', pathMatch: 'full', redirectTo: 'login' },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', useHash: true }),
    FormsModule,
    CommonModule,
    TableModule,
    CalendarModule,
    NgxFileDropModule
  ],
  declarations: [
    // TrolleyMealDistComponent
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
