import { Component, OnInit, OnDestroy } from '@angular/core';
import { LanguageService } from 'src/app/Services/language.service';
import { PermissionsService } from 'src/app/Services/permissions.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { LoginService } from 'src/app/Services/Login.service';
import { Subscription } from 'rxjs';
import { RestockCapService } from 'src/app/Services/restock-cap.service';
import { RestockCampRequest, RestockCapTable } from './restock-cap.model';

declare var $;

declare global {
  interface Blob {
    saveFile(fileName: string): void;
  }
}
Blob.prototype.saveFile = function (fileName: string) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(this);
    a.href = url;
    a.download = fileName;
    a.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, 0);
};

@Component({
  selector: 'app-restock-cap',
  templateUrl: './restock-cap.component.html',
  styleUrls: ['./restock-cap.component.css']
})
export class RestockCapComponent implements OnInit, OnDestroy {

  //Imagenes
  defaultImage = '../../assets/images/compress/TopUpBeverages.jpg';
  image = '../../assets/images/TopUpBeverages.jpg';

  private subscription: Subscription = new Subscription();
  @BlockUI() blockUI: NgBlockUI;
  formulario: UntypedFormGroup;
  public flagDownload: string;

  public language;
  public languageGlobal;
  public languageModule;
  // Variables permisos --------------
  public canCreate;
  public canRead;
  public canUpdate;
  public canDelete;

  public cols = [];

  public restockCapAdd: RestockCapTable = {
    fileName: null,
    fileBase64: null,
    idUser: null
  };

  deleteRequest: { IdRestockCap: any; idUser: number; };

  public validExcel = true;
  excelHref: any;
  pdfSrc: any;
  messages = [];
  columnsMessage: { field: string; header: any; }[];
  msgDelete: string;

  public idUser: number;
  public restockCap: Array<RestockCampRequest>

  // Variable que se utiliza para descargar el archivo pdf
  blob: any;

  constructor(private languagueService: LanguageService,
    private permissionsService: PermissionsService,
    private toastr: ToastrService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private loginService: LoginService,
    private restockCapService: RestockCapService) {

    this.language = languagueService.getLanguage();
    this.languageGlobal = this.language.global;
    this.languageModule = this.language.modules.restockCap;
    this.languagueService.changeLanguage.subscribe(
      English => {
        this.language = languagueService.getLanguage();
        this.languageGlobal = this.language.global;
        this.languageModule = this.language.modules.restockCap;
        this.generateColumns();
      }
    )

    this.canCreate = permissionsService.canView(1, 2, 4, 'C');
    this.canRead = permissionsService.canView(1, 2, 4, 'R');
    this.canUpdate = permissionsService.canView(1, 2, 4, 'U');
    this.canDelete = permissionsService.canView(1, 2, 4, 'D');
  }

  ngOnInit() {
    let personal = this.loginService.getUser();
    this.idUser = parseInt(personal.idUser.toString());
    this.getData();
    this.formulario = this.fb.group({
      potentialFile: new UntypedFormControl()
    });

    if (personal.idArea === 1) {
      this.flagDownload = 'provider'
    } else if (personal.idArea === 18) {
      this.flagDownload = 'provider'
    } else {
      this.flagDownload = 'notProvider'
    }
  }

  getData() {
    this.blockUI.start(this.languageGlobal.loading);
    this.subscription.add(this.restockCapService.getRestockAllData(this.idUser).
      subscribe(
        (data: any) => {
          if (data.ClassInfo.Status === 200) {
            this.madeTable(data.RestockCap);
          }
        },
        error => {
          this.blockUI.stop();
          if (error.status === 401 || error.status === 0) {
            this.blockUI.stop();
            this.toastr.error(this.languageGlobal.sessionExpired, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
            localStorage.setItem('logued', 'false');
            this.router.navigate(['/login']);
          } else if (error.status === 404) {
            this.blockUI.stop();
            this.toastr.error(this.languageGlobal.tryAgain, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
          } else if (error.status === 500 || error.status === 400) {
            this.blockUI.stop();
            this.toastr.error(this.languageGlobal.connectionError, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
          }
        }
      )
    );
  }

  generateColumns() {
    this.cols = [
      { field: 'IdRestockCap', header: this.languageModule.idRestockCap },
      { field: 'Name', header: this.languageModule.nameRestock },
      { field: 'Station', header: this.languageModule.station },
      { field: 'Region', header: this.languageModule.region }
      // { field: 'Status', header: this.languageModule.status }
    ];
  }

  madeTable(data: any) {
    this.restockCap = [];
    this.restockCap = data;
    this.cols = [
      { field: 'IdRestockCap', header: this.languageModule.idRestockCap },
      { field: 'Name', header: this.languageModule.nameRestock },
      { field: 'Station', header: this.languageModule.station },
      { field: 'Region', header: this.languageModule.region }
      // { field: 'Status', header: this.languageModule.status }
    ];

    this.blockUI.stop();
  }

  openAddModal() {
    $('#addModal').modal('show');
    $("body").removeAttr("style");
    this.restockCapAdd.idUser = this.idUser;
    // this.restockCapAdd.lang = localStorage.getItem('language') === 'true';
  }

  closeModalAdd() {
    $('#addModal').modal('hide');
    $("body").change(function () {
      $(this).val('style');
    });
  }

  changeListener($event, excelType) {
    if ($event.target.files[0]) {
      const fileSize = $event.target.files[0].size;
      let validSize = this.handleFileSelected(fileSize);

      if (validSize) {
        this.validExcel = true;
        let fileName = $event.target.files[0].name;
        var regex = /^(TOPE_REABASTO_LIQUIDOS)(_)([A-Z]{3})(_)([A-Z]{3})(.xlsx|.xls|.XLSX|.XLS)$/;
        let correctFileName = regex.test(fileName) ? true : false;

        if (correctFileName) {
          if (excelType === 1) {
            this.restockCapAdd.fileName = $event.target.files[0].name;
          } else {
            this.restockCapAdd.fileName = $event.target.files[0].name;
          }
          this.readThis($event.target, excelType);
        } else {
          this.validExcel = false;
          this.toastr.error(this.languageModule.excelFileNameError, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
        }


      } else {
        this.validExcel = false;
        this.toastr.error(this.languageModule.excelErrorMsg, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
      }
    }
  }

  async readThis(inputValue: any, imageType) {
    let file: File = inputValue.files[0];
    let myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      if (imageType === 1) {
        this.restockCapAdd.fileBase64 = myReader.result as string;
      } else {
        this.restockCapAdd.fileBase64 = myReader.result as string;
      }
    }
    myReader.readAsDataURL(file);
  }

  handleFileSelected(fileSize) {
    let size = fileSize / 1000 / 1000;

    if (size <= 3) {
      return true
    }
    return false;
  }

  getNameFileImage(isEdit) {
    if (isEdit) {
      if (this.restockCapAdd.fileName === '' || this.restockCapAdd.fileName === null) {
        return this.languageModule.labelExcel;
      } else {
        return this.restockCapAdd.fileName;
      }
    } else {
      if (this.restockCapAdd.fileName === '' || this.restockCapAdd.fileName === null) {
        return this.languageModule.labelExcel;
      } else {
        return this.restockCapAdd.fileName;
      }
    }
  }

  madeTableMessages(data) {
    this.messages = [];
    data.forEach(d => {
      this.messages.push({
        codeServ: d.codeServ,
        descServ: d.descServ,
        errorMessages: (localStorage.getItem('language') === 'true') ? d.errorMessagesEn : d.errorMessagesEs,
        isInsert: d.isInsert ? this.languageGlobal.yes : 'No'
      });
    });


    this.columnsMessage = [
      { field: 'codeServ', header: this.languageModule.codServ },
      { field: 'descServ', header: this.languageModule.descServ },
      { field: 'errorMessages', header: this.languageModule.errorMessage },
      { field: 'isInsert', header: this.languageModule.isInsert }
    ];
  }

  clear() {
    this.formulario.reset();
    this.restockCapAdd = {
      idUser: null,
      fileName: null,
      fileBase64: null
    };
  }

  Add() {
    if (this.restockCapAdd.fileBase64 === null) {
      this.toastr.warning(this.languageModule.NoFileMsg, this.languageGlobal.warning, { positionClass: 'toast-bottom-full-width', closeButton: true });
    } else if (!this.validExcel) {
      this.toastr.error(this.languageModule.excelErrorMsg, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
    } else {
      this.blockUI.start(this.languageGlobal.adding);
      this.restockCapAdd.idUser = this.idUser;

      let addsendlist: any[] = [];
      addsendlist.push(this.restockCapAdd);

      this.subscription.add(this.restockCapService.add(addsendlist).subscribe((data: any) => {
        this.blockUI.stop();
        if (data >= 1) {
          this.blockUI.stop();
          // this.madeTableMessages(data.addOnBoardBeans);
          $("#messageModal").modal("show");

          this.toastr.info(this.languageModule.AddMsg, this.languageGlobal.success, { positionClass: 'toast-bottom-full-width', closeButton: true });
          this.getData();
          $('#addModal').modal('hide');
                  $("body").change(function () {
                    $(this).val('style');
                  });
        }
        else {
          this.madeTableMessages(data.addOnBoardBeans);
          $("#messageModal").modal("show");
          this.blockUI.stop();
          this.toastr.error(this.languageModule.AddMsgAgain, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
        }
        this.clear();
      }, error => {
        this.blockUI.stop();
        if (error.status === 401 || error.status === 0) {
          this.blockUI.stop();
          this.toastr.error(this.languageGlobal.sessionExpired, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
          localStorage.setItem('logued', 'false');
          this.router.navigate(['/login']);
        } else if (error.status === 404) {
          this.blockUI.stop();
          this.toastr.error(this.languageGlobal.tryAgain, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
        } else if (error.status === 500 || error.status === 400) {
          this.blockUI.stop();
          this.toastr.error(this.languageGlobal.connectionError, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
        }
        this.clear()
      }))
    }
  }

  deleteModal(table) {
    $("#deleteModal").modal("show");

    this.deleteRequest = {
      IdRestockCap: table.IdRestockCap,
      idUser: this.idUser
    }
    this.msgDelete = `${table.Name}`;

  }

  delete() {
    this.blockUI.start(this.languageGlobal.loading);
    this.subscription.add(this.restockCapService.delete(this.deleteRequest)
      .subscribe(
        (data: any) => {
          if (!data) {
            this.blockUI.stop();
            this.toastr.success(this.languageModule.DeleteMsg + ' ' + this.msgDelete, this.languageGlobal.success, { positionClass: 'toast-bottom-full-width', closeButton: true });
            this.getData();
            $("#deleteModal").modal("hide");
          } else {
            this.blockUI.stop();
            this.toastr.error(this.languageModule.DeleteMsgAgain, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
          }
          this.clear();
        },
        error => {
          this.blockUI.stop();
          if (error.status === 401 || error.status === 0) {
            this.blockUI.stop();
            this.toastr.error(this.languageGlobal.sessionExpired, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
            localStorage.setItem('logued', 'false');
            this.router.navigate(['/login']);
          } else if (error.status === 404) {
            this.blockUI.stop();
            this.toastr.error(this.languageGlobal.tryAgain, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
          } else if (error.status === 500 || error.status === 400) {
            this.blockUI.stop();
            this.toastr.error(this.languageGlobal.connectionError, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
          }
        }
      )
    )
  }

  downloadExcel(c) {
    this.subscription.add(this.restockCapService.getExcel(c.IdRestockCap)
      .subscribe((data: any) => {
        if (data.ClassInfo.Status === 200) {
          this.excelHref = data.FileInfo;
          var element = document.createElement('a');
          element.setAttribute('href', 'data:application/vnd.ms-excel;base64,' + encodeURIComponent(this.excelHref));
          element.setAttribute('download', c.Name);

          element.style.display = 'none';
          document.body.appendChild(element);

          element.click();

          document.body.removeChild(element);
        }
        this.blockUI.stop();
      },
        error => {
          this.blockUI.stop();
          if (error.status === 401 || error.status === 0) {
            this.blockUI.stop();
            this.toastr.error(this.languageGlobal.sessionExpired, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
            localStorage.setItem('logued', 'false');
            this.router.navigate(['/login']);
          } else if (error.status === 404) {
            this.blockUI.stop();
            this.toastr.error(this.languageGlobal.tryAgain, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
          } else if (error.status === 500 || error.status === 400) {
            this.blockUI.stop();
            this.toastr.error(this.languageGlobal.connectionError, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
          }
        }
      )
    )
  }

  pdfViewer(c) {
    this.subscription.add(this.restockCapService.getPdf(c.IdRestockCap)
      .subscribe((data: any) => {
        if (data.ClassInfo.Status === 200) {
          if (c != null && c.Name != null) {
            const byteArray = new Uint8Array(atob(data.FileInfo).split('').map(char => char.charCodeAt(0)));
            this.blob = new Blob([byteArray], { type: `application/pdf` });
            this.blob.saveFile(c.Name.split(".")[0] + '.pdf');
          }else {
            this.toastr.error(this.languageGlobal.fileNotFound, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
          }
        }
        this.blockUI.stop();
      },
        error => {
          this.blockUI.stop();
          if (error.status === 401 || error.status === 0) {
            this.blockUI.stop();
            this.toastr.error(this.languageGlobal.sessionExpired, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
            localStorage.setItem('logued', 'false');
            this.router.navigate(['/login']);
          } else if (error.status === 404) {
            this.blockUI.stop();
            this.toastr.error(this.languageGlobal.tryAgain, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
          } else if (error.status === 500 || error.status === 400) {
            this.blockUI.stop();
            this.toastr.error(this.languageGlobal.connectionError, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
          }
        })
    )
  }

  closeModal(element) {
    $(element).modal('hide');
    $("body").change(function () {
      $(this).val('style');
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
