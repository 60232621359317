import { Component } from '@angular/core';
import { LanguageService } from './Services/language.service';
import { Router } from '@angular/router';
import { LoginService } from './Services/Login.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  
      
   
  constructor(private languageService: LanguageService,
              private router: Router, private loginService: LoginService
              ) {
                if( !localStorage.getItem('language') ){
                  localStorage.setItem('language', 'false')
                }
  }

  title = 'AngularPab';
  isLogued(){
    if(localStorage.getItem('logued')=='true'){
      return true;
      
    }
    return false;
  }
}
