import { Component, OnInit, ɵConsole, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../../Services/Login.service';
import { NavbarLoguedComponent } from '../share/navbar-logued/navbar-logued.component' ;
import { sha256, sha224 } from 'js-sha256';
import { CurrentDataService } from 'src/app/Services/current-data.service';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/Services/language.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  @BlockUI() blockUI: NgBlockUI;
  credentials = {
    Username: '',
    Password: ''
  };

  public status : any;
  public showPsw = false;
  language: any;
  languageGlobal: any;
  languageModule: any;
  isRedirectToIrregularity: boolean = false;
  constructor(private router: Router, private toastr: ToastrService,private loginService: LoginService,private navLogued : NavbarLoguedComponent,
    private languagueService: LanguageService, private currentData: CurrentDataService) {
    localStorage.clear();
    localStorage.setItem('logued', 'false');

    if( !localStorage.getItem('language') ){
      localStorage.setItem('language', 'false')
    }

    this.language = languagueService.getLanguage();
    this.languageGlobal = this.language.global;
    this.languageModule = this.language.modules.login;

    this.subscription.add(this.languagueService.changeLanguage.subscribe(
      isEnglish => {
        this.language = languagueService.getLanguage();
        this.languageGlobal = this.language.global;
        this.languageModule = this.language.modules.login;
      }
    ));
    if(sessionStorage.getItem('responsible')){
      let isUndefined = sessionStorage.getItem('responsible')
      if(  isUndefined === "undefined") {
        sessionStorage.removeItem('responsible')
        sessionStorage.removeItem('folio')
      }else {
        this.toastr.warning( localStorage.getItem('language') === 'true' ?  `Your session expired.. To access the irregularity you must log in first` : `Su sesión expiró.. Para acceder a la irregularidad debes iniciar sesión primero`, 'Error' , { positionClass: 'toast-bottom-full-width', closeButton: true });

      }
    }
  }

  ngOnInit() {
    localStorage.setItem('flagLogin', 'true');

    this.blockUI.stop();
    document.body.classList.add('bg-img');
    this.status = 0;
    this.navLogued.ngOnInit();
  }

  NumText(e: any ){//solo letras y numeros
    let input =e.target.value;
    var out = '';
    //Se añaden caracteres validos
    var filtro = 'abcdefghijklmnñopqrstuvwxyzABCDEFGHIJKLMNÑOPQRSTUVWXYZ1234567890_-.@ ';//Caracteres validos

    for (var i=0; i<input.length; i++)
       if (filtro.indexOf(input.charAt(i)) != -1)
       out += input.charAt(i);
       this.credentials.Username = out
    return out;
  }

  validateUser(data: NgForm){
    console.log(data,'metodo');
    let credentials = {
      Password: sha256(this.credentials.Password.trim()).toLocaleUpperCase(),
      Username: this.credentials.Username
    }
    this.blockUI.start(this.languageModule.login);
    this.subscription.add(this.loginService.login(credentials).subscribe((data: any)=>{
    let status = data.ClassInfo.Status;
    if(status === 205){
      let token = data.Token
      localStorage.setItem('token',token );
      localStorage.setItem('logued', 'true');
      localStorage.emailUser = this.credentials.Username
      this.router.navigate(['/changePassword'])
      this.blockUI.stop();
    } else if(status === 200){
        let token = data.Token
        localStorage.setItem('logued', 'true');
        this.blockUI.stop();
        localStorage.setItem('token',token );
        this.navLogued.ngOnInit();
      setTimeout(() => {
        try {
          if( this.currentData.queryParamsiIrregularities.folio ){
            this.router.navigate(['/reportIrregularities'], { queryParams: { responsible: this.currentData.queryParamsiIrregularities.responsible, folio: this.currentData.queryParamsiIrregularities.folio, idForward: this.currentData.queryParamsiIrregularities.idForward } });
          this.isRedirectToIrregularity = true;
         }else  if(sessionStorage.getItem('responsible')){
           let responsible = sessionStorage.getItem('responsible')
           let folio = sessionStorage.getItem('folio')
           sessionStorage.removeItem('responsible')
           sessionStorage.removeItem('folio')
          this.router.navigate(['/reportIrregularities'], { queryParams: { responsible: responsible , folio: folio , idForward: 1 } });
          this.isRedirectToIrregularity = true;
         }
        } catch (error) {
          if(sessionStorage.getItem('responsible')){
            let responsible = sessionStorage.getItem('responsible')
            let folio = sessionStorage.getItem('folio')
            sessionStorage.removeItem('responsible')
            sessionStorage.removeItem('folio')
           this.router.navigate(['/reportIrregularities'], { queryParams: { responsible: responsible , folio: folio , idForward: 1 } });
            this.isRedirectToIrregularity = true;
          } else {

            this.isRedirectToIrregularity = false;
            sessionStorage.removeItem('responsible')
            sessionStorage.removeItem('folio')
            this.router.navigate(['/main'])
           }
        }
        if( !this.isRedirectToIrregularity ){
          sessionStorage.removeItem('responsible')
          sessionStorage.removeItem('folio')
          this.router.navigate(['/main'])
        }

      }, 2000);

      this.blockUI.stop();

      }else if(status !== 200 || status !== 205){
        this.blockUI.stop();
        this.toastr.error('Usuario o Password incorrectos', 'Verificar credenciales.', {positionClass: 'toast-bottom-full-width', closeButton: true});
      }
    this.blockUI.stop();
    }, error =>{
      this.blockUI.stop();
      this.status =  error.status
      if(this.status == 0){
        this.toastr.error( this.languageModule.tryConnectAgain, 'Error:', {positionClass: 'toast-bottom-full-width', closeButton: true});
      }else{
        let errorStatus = error.error.ClassInfo.Status
        if( errorStatus != null &&  errorStatus > 0){
          if(errorStatus == 401 || errorStatus  == 406){
            this.toastr.error(this.languageModule.msgUserOrPasswordError, this.languageModule.msgVerifyCredentials, {positionClass: 'toast-bottom-full-width', closeButton: true});
          }else{
            this.toastr.error(this.languageModule.tryConnectAgain, 'Error:', {positionClass: 'toast-bottom-full-width', closeButton: true});
          }
        }
      }
    }));
  }

  getSeeschweiler(){
    this.loginService.getSeeschweiler();
  }

  ngOnDestroy(){
    document.body.classList.remove('bg-img');
    this.subscription.unsubscribe();
  }

  toggleShowPsw() {
    this.showPsw = !this.showPsw;
  }
}
